/* ========
intro-lifestage
======== */

.o-intro-lifestage{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  &__content {
    margin-top: 100px;
    padding-bottom: 100px;
    @include mq(sp) {
      margin-top: 0;
      padding: 64px 0;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    bottom: 20px;
    right: calc(50% - 750px);
    pointer-events: none;
    @include mq(sp) {
      width: 218px;
      height: 218px;
      bottom: 20px;
      right: -58px;
    }
    svg{
      fill: #fff;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 50px;
    width: 1000px;
    margin: 0 auto;
    @include mq(sp) {
      flex-wrap: initial;
      gap: 32px;
      width: 100%;
      overflow: auto;
      padding: 10px 64px 40px;
    }
    .c-box-simple{
      @include mq(sp) {
        display: grid;
        width: 240px;
        flex: 0 0 auto;
      }
    }
    .c-box-simple.-col2 {
      @include mq(sp) {
        width: 240px;
      }
    }
    .c-box-simple a{
      @include mq(sp) {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 2;
      }
    }
    .c-box-simple__textarea {
      min-height: initial;
    }
  }
  &__scrollable{
    position: relative;
    z-index: 0;
    @include mq(sp) {
      &:before{
        content: 'Swipe';
        display: block;
        position: absolute;
        font-family: $roboto;
        font-size: 1.1rem;
        letter-spacing: .05em;
        font-weight: 400;
        left: 20px;
        top: 76px;
        transform: rotate(90deg);
        pointer-events: none;
        z-index: 2;
      }
      &:after{
        content: '';
        width: 20px;
        height: 1px;
        background-color: palettes(main);
        display: block;
        position: absolute;
        left: 0;
        top: 86px;
        pointer-events: none;
        z-index: 2;
      }
    }
    & + & {
      margin-top: 50px;
    }
  }
}