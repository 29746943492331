//-----------------------
.c-box-icon{
  margin-left: 20px;
  flex: 0 1 auto;
  @include mq(sp){
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-ttl-case__ja{
    line-height: 1.3;
    color: $color_text;
    margin-bottom: 12px;
    @include mq(sp){
      font-size: 1.4rem;
      margin: 12px 0;
      text-align: center;
    }
  }
  .c-modal-reserve__tel{
    @include mq(sp){
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &:last-of-type{
      margin-left: 24px;
      @include mq(sp){
        margin: 12px 0 0;
      }
    }
  }
  .c-modal-reserve__en{
    font-family: $cormorant;
    font-size: 1.8rem;
    letter-spacing: .15em;
    color: $color_text;
    display: flex;
    align-items: center;
    line-height: 1;
    @include mq(sp){
      width: 100%;
      text-align: center;
      justify-content: center;
      font-size: 1.3rem;
      margin-bottom: 4px;
    }
    &:before{
      content: "";
      display: block;
      width: 10px;
      height: 1px;
      background: $color_main;
      margin-right: 4px;
    }
  }
  .c-modal-reserve__number{
    font-family: $cormorant;
    font-size: 2.8rem;
    letter-spacing: .08em;
    color: $color_text;
    line-height: 1;
    @include mq(sp){
      font-size: 2rem;
    }
  }
  .c-modal-reserve__textarea{
    margin: 0 60px 0 0;
    @include mq(sp){
      width: 100%;
      margin: 0;
      .c-desc{
        text-align: center;
      }
    }
  }
  &__item{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .c-btn{
    width: 120px;
    height: 74px;
    @include mq(sp){
      width: 240px;
      height: 42px;
      margin-top: 12px;
    }
  }
}