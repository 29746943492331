//-----------------------
.c-modal-dress{
  opacity: 0;
  pointer-events: none;
  transition: 1s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  margin: 0 auto;
  width: 100%;
  background-color: rgba(#323232,.95);
  padding: 100px 0 0;
  @include mq(sp) {
    padding: 72px 15px 100px;
    overflow-y: scroll;
  }

  &.-active{
    opacity: 1;
    pointer-events: auto;
  }
  &__img{
    width: 480px;
    margin: 0 auto;
    position: relative;
    @include mq(sp) {
      width: 100%;
      max-width: 480px;
    }
    img{
      width: 100%;
    }
  }
  &__meta{
    position: absolute;
    color: #fff;
    right: calc(100% + 40px);
    bottom: 0;
    @include mq(sp) {
      position: relative;
      right: 0;
      margin: 24px 0 0;
    }
    .c-copy-small{
      margin: 0 0 24px;
      @include mq(sp) {
        margin: 0 0 8px;
      }
    }
  }
  &__data{
    white-space: nowrap;
    dl{
      display: flex;
      align-items: center;
    }
    dt{
      font-family: $cormorant;
      font-size: 1.6rem;
      letter-spacing: .05em;
      &:before{
        content: '―';
        color: palettes(main);
        margin: 0 .5em 0 0;
      }
      &:after{
        content: ':';
        margin: 0 .25em;
      }
    }
    dd{
      font-size: 1.4rem;
    }
  }
}