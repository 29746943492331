//-----------------------
.c-item-plan{
  width: 480px;
  @include mq(sp) {
    width: 100%;
  }
  a{
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:hover{
        .c-item-plan__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    @include mq(sp) {
    }
  }
  &__img{
    position: relative;
    z-index: 0;
    flex: 0 0 auto;
    width: 220px;
    height: 210px;
    overflow: hidden;
    margin: 40px 0;
    @include mq(sp) {
      width: 120px;
      height: 120px;
      margin: 24px 0;
      &:before{
        content: '';
        display: block;
        padding: 73.33% 0 0;
      }
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
    img{
      display: none;
    }
  }
  &__textarea{
    padding: 40px 40px 40px 30px;
    @include mq(sp) {
      padding: 24px 24px 40px;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 40px;
      right: 0;
      bottom: 0;
      background-color: palettes(main,lightest);
      pointer-events: none;
      @include mq(sp) {
        left: 32px;
      }
    }
  }
  .c-copy-basic{
    line-height: 1.5;
    letter-spacing: .05em;
    margin: 0 0 16px;
  }
  &__price{
    color: palettes(main);
    margin: 0 0 1em;
    padding: 0 0 1em;
    border-bottom: 1px solid palettes(main,light);
    span{
      line-height: 1;
      font-size: 2.8rem;
      letter-spacing: .05em;
      @include mq(sp) {
        font-size: 2.2rem;
      }
    }
  }
  .c-desc{
    line-height: 1.8;
  }
  &__more{
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: palettes(main);
    color: #fff;
    width: 92px;
    height: 30px;
    font-family: $cormorant;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0 0;
    @include mq(sp) {
      font-size: 1rem;
      width: 56px;
      height: 22px;
      padding: 0;
    }
  }
}