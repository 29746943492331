/* ========
intro-ceremony
======== */

.o-intro-ceremony{
  background-color: palettes(main,lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 80px;
  }
  .l-inner{
    width: 1000px;
    background-color: #fff;
    margin-top: -80px;
    padding: 56px 0 100px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      margin-top: -40px;
      width: calc(100% - 40px);
      padding: 40px 0 20px;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    top: 220px;
    right: -96px;
    pointer-events: none;
    @include mq(sp) {
      width: 143px;
      height: 218px;
      top: 140px;
      right: -18px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  &__head{
    margin: 0 0 100px;
    @include mq(sp) {
      padding: 0 30px;
      margin: 0 0 56px;
    }
    >.c-desc{
      text-align: center;
      @include mq(sp) {
        text-align: justify;
      }
    }
  }
  .c-ttl-section{
    margin: 0 0 40px;
  }
  .c-copy-basic{
    margin: 0 0 24px;
    text-align: center;
    @include mq(sp) {
      text-align: left;
    }
  }
  &__content{
    .c-box-caption{
      &:nth-child(n+2){
        margin: 100px 0 0;
        @include mq(sp) {
          margin: 30px 0 0;
        }
      }
      &.-full{
        @include mq(pc) {
          .c-box-caption__img{
            width: calc(100% + 100px);
            min-width: 100%;
            height: 580px;
          }
          .c-box-caption__textarea{
            width: 570px;
            padding: 0 60px;
            &:before{
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}