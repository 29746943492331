/* ========
list-propose-voice
======== */
.o-list-propose-voice {
  background-color: #fff;
  padding: 80px 0 100px;
  @include mq(sp) {
    padding: 40px 0 80px;
  }
  .l-inner {
    width: 1000px;
    @include mq(sp) {
      width: 100%;
      padding: 0 15px;
    }
    & > .c-ttl-section {
      margin-bottom: 20px;
    }
    & > .c-copy-basic {
      text-align: center;
      font-size: 1.6rem;
      margin-bottom: 50px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    .c-box-simple {
      width: calc(25% - 24px);
      margin: 0 12px;
      @include mq(sp) {
        width: calc(50% - 24px);
      }
      a:before {
        display: none;
      }
      &__textarea {
        min-height: auto;
        padding-left: 0;
        padding-right: 0;
      }
      &__more {
        right: auto;
        border-bottom: 1px solid #98806e;
      }
    }
  }
  .c-btn{
    margin-top: 30px;
  }
}