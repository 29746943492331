/* ========
foot-content
======== */

.o-foot-content{
  background: #323232 url(../images/common/bg_texture01.png) center top repeat-x;
  background-size: 1385px;
  padding: 80px 0 40px;
  @include mq(sp) {
    padding: 50px 0;
    background-size: 750px;
  }
  &__btns{
    display: flex;
    justify-content: center;
    margin: 64px 0;
    @include mq(sp) {
      flex-direction: column-reverse;
      margin: 40px 0;
    }
    .c-btn-large{
      margin: 0 20px;
      @include mq(sp) {
        margin: 20px auto;
      }
    }
  }
  &__col3{
    display: flex;
    justify-content: center;
    margin: 0 0 80px;
    @include mq(sp) {
      display: block;
      border-top: 1px solid #505050;
    }
  }
  &__links{
    width: 280px;
    margin: 0 0 0 56px;
    @include mq(sp) {
      width: 100%;
      margin: 0;
    }
    .c-link-sp-accordion{
      margin: 0 0 32px;
      @include mq(sp) {
        margin: 0;
      }
    }
    a{
      transition: opacity .3s ease;
      @include mq(pc) {
        &:hover{
          opacity: .6;
        }
      }
    }
  }
}