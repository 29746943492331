/* ========
reserve-line
======== */

.o-reserve-line {
  width: 500px;
  margin-top: 50px;
  background: #fff;
  padding: 50px 0;
  text-align: center;
  @include mq(sp) {
    width: 100%;
    margin-top: 40px;
    padding: 40px 0;
  }
}

.o-reserve-line__title {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: .02em;
  line-height: 2;
  @include mq(sp) {
    font-size: 1.8rem;
  }
}

.o-reserve-line__text {
  margin-top: 30px;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2;
  @include mq(sp) {
    margin-top: 20px;
    font-size: 1.3rem;
  }
}

.o-reserve-line .c-btn {
  margin: 20px auto 0;
  @include mq(sp) {
    width: 220px;
  }
}

.o-reserve-line .iconSvg.-line {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 8px;
  background: $color_main;
  margin-right: 10px;
  @include mq(sp) {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
}

.o-reserve-line .iconSvg.-line svg {
  width: 26px;
  height: 25px;
  fill: #fff;
  @include mq(sp) {
    width: 21px;
    height: 20px;
  }
}

.o-reserve-line .c-btn a:hover {
  .iconSvg.-line svg {
    fill: #fff;
  }
}