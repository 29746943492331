/* ========
lead-contact02
======== */

.o-lead-contact02{
  text-align: center;
  &__ttl{
    font-size: 2.4rem;
    letter-spacing: .1em;
    color: $color_text;
    font-weight: 500;
  }
  .c-desc{
    margin-top: 10px;
    text-align: center;
    @include mq(sp){
      padding: 0 20px;
    }
  }
  &__box{
    width: 100%;
    background: url(../images/plan/img_plan_bg.jpg) center center no-repeat;
    background-size: cover;
    margin-top: 40px;
    .l-inner{
      max-width: 1000px;
      margin: 0 auto;
      padding: 80px 0;
      @include mq(sp){
        padding: 40px 20px;
      }
    }
  }
  &__textarea{
    background: #fff;
    padding: 64px 0;
    position: relative;
    overflow: hidden;
    z-index: 0;
    @include mq(sp){
      padding: 30px 0;
    }
    .c-desc{
      font-weight: 700;
      margin-top: 10px;
    }
    .o-list-plan__deco{
      width: 168px;
      right: -12px;
    }
  }
  &__tel{
    display: inline-flex;
    align-items: center;
    margin-top: 24px;
    @include mq(sp){
      flex-wrap: wrap;
    }
    .-ttl,.-number{
      font-family: $cormorant;
      letter-spacing: .02em;
      font-weight: 500;
      @include mq(sp){
        width: 100%;
        line-height: 1.4;
      }
    }
    .-ttl{
      font-size: 2.2rem;
      color: $color_main;
    }
    .-number{
      font-size: 2.2rem;
      color: $color_text;
      @include mq(pc){
        margin-left: 16px;
      }
      @include mq(sp){
        font-size: 3rem;
      }
    }
    .-time{
      font-size: 1.3rem;
      letter-spacing: .02em;
      color: $color_text;
      @include mq(pc){
        margin: 4px 0 0 12px;
      }
      @include mq(sp){
        width: 100%;
      }
    }
  }
  &__btn{
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @include mq(sp){
      flex-wrap: wrap;
      padding: 0 60px;
    }
    .c-btn{
      width: 300px;
      &:nth-child(2){
        margin-left: 16px;
        @include mq(sp){
          margin: 12px 0 0;
        }
      }
      .iconSvg.-before{
        position: static;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}