/* ========
calendar
======== */

.c-calendar{
  width: 100%;
  position: relative;
  z-index: 0;
  &__item{
    width: 100%;
    position: relative;
    z-index: 0;
  }
  &__label{
    font-family: $cormorant;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: .15em;
    text-align: center;
    display: block;
    margin: 0 0 16px;
    line-height: 1;
    small{
      font-size: 2rem;
      letter-spacing: .15em;
    }
  }
  &__arrow{
    width: 32px;
    height: 32px;
    position: absolute;
    background: transparent center center no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 12 20'%3E%3Cdefs/%3E%3Cpath fill='%2390806e' d='M12 0h-2L0 10l10 10h2L2 10z'/%3E%3C/svg%3E");
    background-size: 12px 20px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 0;
    &.-prev{
      left: 0;
    }
    &.-next{
      right: 0;
      transform: scale(-1,1);
    }
    &.slick-disabled{
      opacity: .33;
      pointer-events: none;
    }
  }
  table{
    width: 100%;
    table-layout: fixed;
    th{
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0;
    }
    td{
      background-color: #fff;
      font-family: $cormorant;
      font-size: 2rem;
      line-height: 1.6;
      font-weight: 400;
      letter-spacing: .2em;
      text-indent: .2em;
      text-align: center;
      vertical-align: middle;
      padding: 8px;
      border: 1px solid palettes(main,lightest);
      cursor: pointer;
      &.-today{
        background-color: palettes(main);
        color: #fff;
      }
      &:nth-child(1){
        background-color: #fae8e8;
      }
      &:nth-child(7){
        background-color: #d6e5e8;
      }
      &.-current{
        background-color: palettes(main);
        color: #fff;
      }
    }
  }
}