//-----------------------
.c-item-pagelink{
  width: 300px;
  margin: 0 50px 0 0;
  @include mq(sp) {
    width: 100%;
  }
  a{
    display: block;
    @include mq(pc) {
      &:hover{
        .c-item-pagelink__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    @include mq(sp) {
      display: flex;
      position: relative;
      z-index: 0;
      padding: 10px 0;
      align-items: center;
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 10px;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: -1;
      }
    }
  }
  &__img{
    height: 180px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: 36%;
      flex: 0 0 auto;
      height: auto;
      &:before{
        content: '';
        display: block;
        padding: 100% 0 0;
      }
    }
    figure{
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img{
      display: none;
    }
  }
  &__textarea{
    background-color: #fff;
    padding: 24px 0;
    position: relative;
    z-index: 0;
    min-height: 208px;
    @include mq(sp) {
      background-color: transparent;
      padding: 0 20px;
      position: static;
      min-height: 0;
    }
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent palettes(main) transparent;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &__ttl{
    font-family: $cormorant;
    font-size: 2.6rem;
    color: palettes(main);
    letter-spacing: .1em;
    text-align: center;
    line-height: 1.32;
    @include mq(sp) {
      font-size: 2.1rem;
      text-align: left;
      line-height: 1;
    }
  }
  &__copy{
    &.c-desc{
      display: block;
      text-align: center;
      margin: 0 0 1em;
      @include mq(sp) {
        text-align: left;
        font-size: 1.1rem;
      }
    }
  }
  &__desc{
    &.c-desc{
      text-align: center;
      line-height: 1.8;
      @include mq(sp) {
        text-align: left;
        font-size: 1.2rem;
      }
    }
  }
}