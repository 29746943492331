/* ========
lead-report
======== */

.o-lead-report{
  padding: 100px 0;
  overflow: hidden;
  @include mq(sp) {
    padding: 20px 20px 60px;
  }
  >.l-inner{
    padding: 64px 50px;
    background-color: palettes(main,lightest);
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 40px 0;
    }
  }
  .c-ttl-section{
    margin: 0 0 56px;
    @include mq(sp) {
      margin: 0 0 32px;
      &__en{
        letter-spacing: .05em;
      }
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    top: 0;
    right: -96px;
    pointer-events: none;
    @include mq(sp) {
      width: 218px;
      height: 218px;
      right: -56px;
    }
    svg{
      fill: #fff;
    }
  }
  .c-slider-sp{
    @include mq(sp) {
      position: relative;
      z-index: 0;
      width: 240px;
      margin: 0 auto;
      .slick-list{
        overflow: visible;
      }
      .c-item-report{
        margin: 0 16px;
      }
      &__arrow{
        width: 50px;
        height: 50px;
        background-color: #000;
        position: absolute;
        background: transparent center center no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 16 28'%3E%3Cdefs/%3E%3Cpath fill='%2398806e' d='M16 0h-2L0 14l14 14h2L2 14z'/%3E%3C/svg%3E");
        background-size: 16px 28px;
        cursor: pointer;
        z-index: 1;
        &.-prev{
          top: 35%;
          left: -40px;
          transform: translate(0,-50%);
        }
        &.-next{
          top: 35%;
          right: -40px;
          transform: translate(0,-50%)scale(-1,1);
        }
      }
    }
  }
}