/* ========
reserve-latest
======== */

.o-reserve-latest{
  width: 500px;
  @include mq(sp) {
    width: 100%;
  }
  .c-copy-basic{
    text-align: center;
    letter-spacing: .02em;
    margin: 0 0 24px;
    @include mq(sp) {
      margin: 0 0 32px;
    }
  }
  &__item{
    display: none;
    &.-current{
      display: block;
    }
    &.-tel{
      @include mq(pc) {
        li{
          pointer-events: none;
        }
      }
    }
    &:nth-child(n+2){
      margin: 32px 0 0;
      @include mq(sp) {
        margin: 24px 0 0;
      }
    }
  }
  &__label{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    &:before, &:after{
      content: '';
      display: block;
      width: 20px;
      height: 1px;
      background-color: palettes(main);
      flex: 0 0 auto;
    }
    >span{
      margin: 0 16px;
      font-family: $cormorant;
      font-size: 4.2rem;
      font-weight: 400;
      letter-spacing: .1em;
      @include mq(sp) {
        font-size: 3.4rem;
      }
      small{
        font-size: 3.2rem;
        display: inline-block;
        transform: translate(0,-.1em);
        @include mq(sp) {
          font-size: 2.4rem;
        }
      }
      span{
        font-family: $serif;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: .1em;
      }
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 160px;
      cursor: pointer;
      text-align: center;
      padding: 16px 0;
      background-color: #fff;
      margin: 0 10px 10px 0;
      box-shadow: 0 0 8px rgba(palettes(main),.4);
      @include mq(pc) {
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include mq(sp) {
        width: calc(33.33% - 4px);
        padding: 12px 0;
        margin: 0 6px 6px 0;
        &:nth-child(3n){
          margin-right: 0;
        }
      }
    }
    & + .c-desc{
      // background-color: #fff;
      padding: 4px 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(sp) {
        display: block;
      }
      span{
        font-family: $cormorant;
        font-size: 2.6rem;
        color: palettes(main);
        line-height: 1;
        display: inline-block;
        margin: 0 0 0 8px;
        @include mq(sp) {
          display: block;
        }
      }
    }
  }
  &__label{
    margin: 0 0 16px;
    @include mq(sp) {
      margin: 0 0 12px;
    }
  }
  &__time{
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1;
    margin: 0 0 8px;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
  &__status{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
      font-size: 1.3rem;
    }
    .iconSvg{
      display: block;
      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;
    }
  }
}