//-----------------------
.c-btn-menu{
  @include mq(pc) {
    display: none;
  }
  position: fixed;
  top: 4px;
  right: 4px;
  width: 56px;
  height: 56px;
  z-index: 999;
  cursor: pointer;
  &:before, &:after{
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    background-color: palettes(main);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: .4s ease;
  }
  &:before{
    transform: translate(0,4px)rotate(0deg);
  }
  &:after{
    transform: translate(0,12px)rotate(0deg);
  }
  p{
    font-family: $roboto;
    font-size: 1.2rem;
    letter-spacing: .04em;
    font-weight: 600;
    color: palettes(main,dark);
    position: absolute;
    left: 0;
    right: 0;
    top: 14px;
    text-align: center;
    line-height: 1;
    // &:before{
    //   content: 'close';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   color: transparent;
    // }
  }

  &.-active{
    p{
      color: transparent;
      // &:before{
      //   color: #fff;
      // }
    }
    &:before, &:after{
      background-color: #fff;
      width: 24px;
    }
    &:before{
      transform: translate(0,0)rotate(135deg);
    }
    &:after{
      transform: translate(0,0)rotate(-135deg);
    }
  }

  // color
  &.-black{
    p{
      color: #fff;
    }
    &:before, &:after{
      background-color: #323232;
    }
    &.-active{
      p{
        color: transparent;
        // &:before{
        //   color: #fff;
        // }
      }
      &:before, &:after{
        background-color: #fff;
      }
    }
  }
}