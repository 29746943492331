/* ========
lead-fair
======== */

.o-lead-fair{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 100px 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  .l-inner{
    padding: 0 100px;
    @include mq(sp) {
      padding: 0 30px;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    top: 0;
    left: calc(50% - 800px);
    pointer-events: none;
    svg{
      fill: #fff;
    }
  }
  .c-ttl-section{
    margin: 0 0 32px;
    @include mq(sp) {
      margin: 0 0 24px;
    }
  }
  &__content{
    margin: 56px 0 0;
    .c-btn{
      margin-top: 60px;
      @include mq(sp) {
        width: 190px;
        height: 42px;
        margin-top: 24px;
        a{
          font-size: 1.4rem;
        }
        .iconSvg{
          right: 8px;
        }
      }
    }
  }
}