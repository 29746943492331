/* ========
lead-room
======== */

.o-lead-room{
  background-color: palettes(blue,lightest);
  padding: 100px 0;
  @include mq(sp) {
    padding: 56px 0;
  }
  .l-inner{
    position: relative;
    z-index: 0;
    @include mq(pc) {
      width: 1000px;
    }
    @include mq(sp) {
      padding: 0 20px;
    }
  }
  .c-ttl-section{
    @include mq(pc) {
      text-align: left;
      position: absolute;
      top: 0;
      left: 0;
    }
    @include mq(sp) {
      margin: 0 0 40px;
    }
  }
  .c-box-caption{
    @include mq(pc) {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      &__img{
        width: 550px;
        flex: 0 0 auto;
        z-index: 1;
        position: relative;
        background: transparent center center no-repeat;
        background-size: cover;
        margin: 0 -50px 56px 0;
        &:before{
          content: '';
          display: block;
          padding: 83.64% 0 0;
        }
        img{
          display: none;
        }
      }
      &__textarea{
        width: 500px;
        padding: 56px;
        &:before{
          top: 0;
          left: 0;
          right: -500px;
          bottom: 0;
          width: auto;
        }
        &__inner{
          padding: 0;
        }
      }
    }
  }
}