//-----------------------
.c-box-images{
  display: flex;
  align-items: flex-start;
  @include mq(sp) {
    display: block;
  }
  &__img{
    width: 550px;
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    @include mq(sp) {
      width: 100%;
    }
    figure{
      background: transparent center center no-repeat;
      background-size: cover;
    }
    img{
      display: none;
    }
    &__main{
      width: 100%;
      height: 380px;
      position: relative;
      z-index: 0;
      overflow: hidden;
      @include mq(sp) {
        height: auto;
        &:before{
          content: '';
          display: block;
          padding: 66.66% 0 0;
        }
      }
      figure{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &.-custom01{
        @include mq(sp) {
          &:before{
            padding-top: 59%;
          }
        }
      }
    }
    &__sub{
      margin: 4px -2px 0;
      display: flex;
      figure{
        width: calc(33.33% - 4px);
        flex: 0 0 auto;
        margin: 0 2px;
        &:before{
          content: '';
          display: block;
          padding: 100% 0 0;
        }
      }
    }
  }
  &__textarea{
    padding: 56px 56px 88px;
    margin: 40px 0 0;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 32px 20px;
      margin: 24px 0 0;
    }
    &:before{
      content: '';
      display: block;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: -280px;
      right: 0;
      bottom: 0;
      z-index: -1;
      pointer-events: none;
      @include mq(sp) {
        left: 0;
      }
    }
    .c-desc{
      p{
        &:nth-child(n+2){
          margin-top: 1em;
        }
      }
    }
  }
  &__num{
    font-family: $quentin;
    font-size: 2.6rem;
    font-weight: 500;
    color: palettes(main);
    text-shadow: 0 0 0 currentColor;
    line-height: 1;
    margin: 0 0 12px;
  }
  .c-copy{
    margin: 24px 0;
  }
  .c-btn{
    position: absolute;
    right: 56px;
    bottom: -27px;
    @include mq(sp) {
      position: relative;
      right: 0;
      bottom: 0;
      margin: 24px auto 0;
    }
  }


  // layout
  &.-rev{
    flex-direction: row-reverse;
    .c-box-images{
      &__textarea{
        &:before{
          left: 0;
          right: -280px;
        }
      }
    }
    .c-btn{
      left: 56px;
      right: auto;
    }
  }
}