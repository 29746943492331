/* ========
lead-sns
======== */

.o-lead-sns{
  border-top: 1px solid #dfdad6;
  padding: 40px 0;
  @include mq(sp) {
    padding: 30px 0;
  }
  .o-list-sns{
    &__item{
      p{
        color: #323232;
        text-shadow: 0 0 0 currentColor;
      }
    }
  }
}