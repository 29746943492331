//-----------------------
.c-modal-brand{
  opacity: 0;
  pointer-events: none;
  transition: 1s ease;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  margin: 0 auto;
  width: 100%;
  background-color: palettes(main,lightest);
  padding: 100px 0 0;
  @include mq(sp) {
    padding: 48px 15px 100px;
    overflow-y: scroll;
  }

  &.-active{
    opacity: 1;
    pointer-events: auto;
  }

  .c-overlay{
    background-color: palettes(main,lightest);
  }
  .c-btn-close{
    z-index: 5;
  }
  .l-inner{
    max-width: 900px;
    position: relative;
    z-index: 0;
  }
  &__textarea{
    width: 100%;
    padding: 0 530px 64px 0;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 0 0 40px;
    }
    &:before{
      content: '';
      display: block;
      width: 100vw;
      top: -100px;
      left: 50%;
      bottom: 0;
      transform: translate(-50%,0);
      background-color: #fff;
      position: absolute;
      z-index: -1;
      @include mq(sp) {
        bottom: -100px;
      }
    }
  }
  &__logo{
    img{
      width: auto;
      max-width: none;
      height: 36px;
      @include mq(sp) {
        height: 32px;
      }
    }
    .c-desc{
      margin: 16px 0 0;
      @include mq(sp) {
        margin: 12px 0 0;
      }
    }
  }
  .c-copy-basic{
    margin: 40px 0 16px;
    @include mq(sp) {
      margin: 24px 0 12px;
    }
  }
  &__slider{
    position: absolute;
    width: 450px;
    right: 0;
    top: 0;
    @include mq(sp) {
      width: 100%;
      position: relative;
    }
    figure{
      figcaption{
        display: none;
      }
    }
  }
  &__controller{
    position: relative;
    padding: 56px 0 0;
    width: 370px;
    @include mq(sp) {
      width: 100%;
      padding: 24px 0 0;
    }
  }
  &__counter{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: $cormorant;
    font-weight: 400;
    line-height: 1;
  }
  &__numer{
    font-size: 3rem;
    letter-spacing: .15em;
    &:after{
      content: '';
      width: 1px;
      height: .85em;
      margin: .15em .3em -.15em;
      display: inline-block;
      transform: rotate(30deg);
      background-color: currentColor;
    }
  }
  &__denom{
    font-size: 2rem;
    letter-spacing: .15em;
  }
  &__name{
    font-family: $cormorant;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .1em;
    text-align: center;
    margin: 16px 0 0;
    line-height: 1.8;
    &:after{
      content: '';
      display: block;
      width: 1em;
      height: 1px;
      background-color: palettes(main);
      margin: 1em auto 0;
    }
  }
  &__arrow{
    width: 32px;
    height: 32px;
    position: absolute;
    background: transparent center center no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 12 20'%3E%3Cdefs/%3E%3Cpath fill='%2390806e' d='M12 0h-2L0 10l10 10h2L2 10z'/%3E%3C/svg%3E");
    background-size: 12px 20px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 56px;
    &.-prev{
      left: 0;
    }
    &.-next{
      right: 0;
      transform: scale(-1,1);
    }
  }
}