//-----------------------
.c-item-case{
  width: 300px;
  @include mq(sp) {
    width: 100%;
  }
  a{
    display: block;
    width: 100%;
    @include mq(pc) {
      &:hover{
        .c-item-case__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    @include mq(sp) {
      display: flex;
      align-items: center;
    }
  }
  &__img{
    width: 100%;
    position: relative;
    overflow: hidden;
    @include mq(sp) {
      width: 50%;
      max-width: 280px;
      flex: 0 0 auto;
    }
    &:before{
      content: '';
      display: block;
      padding: 66.66% 0 0;
    }
    &:after{
      content: '';
      display: block;
      width: 1px;
      height: 28px;
      background-color: palettes(main);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      transform: translate(0,50%);
      @include mq(sp) {
        content: none;
      }
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
    }
    img{
      display: none;
    }
  }
  &__textarea{
    text-align: center;
    padding: 32px 0 0;
    @include mq(sp) {
      text-align: left;
      padding: 0 16px 0 24px;
    }
    .c-ttl-small-serif{
      margin: 0 0 8px;
    }
    .c-desc{
      text-align: center;
      @include mq(sp) {
        text-align: justify;
      }
    }
  }
}