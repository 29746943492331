//-----------------------
.c-link-divider{
  display: flex;
  @include mq(sp) {
    display: block;
  }
  li{
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: .05em;
    @include mq(sp) {
      font-size: 1.3rem;
    }
    &:nth-child(n+2){
      &:before{
        content: '|';
        margin: 0 1em;
        @include mq(sp) {
          content: none;
        }
      }
    }
    a{
      display: block;
    }
    .iconSvg{
      display: inline-block;
      width: 1em;
      height: 1em;
      margin: 0 0 0 .5em;
      svg{
        fill: palettes(main,light);
      }
    }
  }
}