//-----------------------
.c-box-menu{
  width: 340px;
  height: 280px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  @include mq(pc) {
    &:hover{
      .c-box-menu__content{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  @include mq(sp) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
  &__img{
    width: 100%;
    height: 100%;
    background: transparent center center no-repeat;
    background-size: cover;
    @include mq(sp) {
      height: auto;
      &:before{
        content: '';
        display: block;
        padding: 73.33% 0 0;
      }
    }
    img{
      display: none;
    }
  }
  &__content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#1e1914, .8);
    border: 2px solid palettes(main);
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .6s ease;
    @include mq(sp) {
      position: relative;
      opacity: 1;
      pointer-events: auto;
      padding: 12px;
      flex: 1 1 auto;
      justify-content: flex-start;
      display: none;
    }
  }
  &__ttl{
    font-size: 1.8rem;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 1.4rem;
    }
    &:after{
      content: '';
      width: 1em;
      height: 1px;
      background-color: palettes(main);
      display: block;
      margin: .5em auto;
    }
  }
  &__desc{
    font-size: 1.4rem;
    line-height: 1.8;
    @include mq(sp) {
      font-size: 1.2rem;
      text-align: justify;
    }
  }
}