//-----------------------
.prlxView{
  &.c-ttl-section{
    transform-origin: center top;
    transform: translate(0,1.56em)scale(.92);
    opacity: 0;
    &.-animated{
      transition: 2s cubic-bezier(.12,.86,.34,1);
      opacity: 1;
      transform: translate(0,0)scale(1);
    }
  }
  &[data-anime="fade"]{
    opacity: 0;
    &.-animated{
      opacity: 1;
      transition: 2s ease;
    }
  }
  &[data-anime="fadeToUp"]{
    opacity: 0;
    transform: translate(0,24px);
    &.-animated{
      opacity: 1;
      transition: 2s ease;
      transform: translate(0,0);
    }
  }
}