//-----------------------
.c-box-card{
  background: palettes(blue,lightest);
  padding: 36px 50px;
  position: relative;
  width: 100%;
  @include mq(sp){
    padding: 30px 20px;
  }
  &:before{
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 32px 32px 0 0;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__ttl{
    font-size: 2rem;
    letter-spacing: .2em;
    color: $color_main;
    display: flex;
    align-items: center;
    @include mq(sp){
      font-size: 1.8rem;
    }
    .iconSvg{
      width: 28px;
      height: 26px;
      display: block;
      margin-right: 10px;
      fill: $color_main;
      @include mq(sp){
        width: 20px;
        height: 20px;
      }
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    .c-box-card__item{
      width: 50%;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      letter-spacing: .02em;
      color: $color_text;
      @include mq(sp){
        width: 100%;
        font-size: 1.3rem;
      }
      &:before{
        content: "・";
        display: block;
        margin-right: 4px;
      }
    }
  }
}