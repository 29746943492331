/* ========
intro-menu
======== */

.o-intro-menu{
  padding: 96px 0 0;
  position: relative;
  z-index: 0;
  // background-color: palettes(main,lightest);
  @include mq(sp) {
    padding: 64px 20px 40px;
  }
  // &__deco{
  //   position: absolute;
  //   z-index: -1;
  //   width: 436px;
  //   height: 436px;
  //   top: 100px;
  //   right: calc(50% - 750px);
  //   pointer-events: none;
  //   svg{
  //     fill: #fff;
  //   }
  // }
  &__list{
    display: flex;
    flex-wrap: wrap;
    // width: 1000px;
    margin: 0 -50px;
    @include mq(sp) {
      width: auto;
      margin: 0 -30px;
    }
    .c-box-menu{
      margin: 0 40px 40px 0;
      @include mq(pc) {
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include mq(sp) {
        width: calc(50% - 6px);
        margin: 0 12px 12px 0;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
  .c-ttl-section{
    margin: 0 0 56px;
    @include mq(sp) {
      margin: 0 0 40px;
    }
  }
}