/* ========
intro-media
======== */
.o-intro-media {
  background-color: palettes(main, lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 64px;
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .l-inner {
    width: 1000px;
    position: relative;
    z-index: 0;
    background-color: #fff;
    margin-top: 80px;
    padding: 60px;
    @include mq(sp) {
      margin-top: 40px;
      width: 100%;
      padding: 15px;
    }
    > .c-ttl {
      font-size: 2.8rem;
      letter-spacing: 0.2em;
      margin-bottom: 50px;
      margin-top: 80px;
      text-align: center;
      font-weight: normal;
    }
    .c-box-images {
      .c-copy {
        margin-top: 0;
        font-size: 2.2rem;
      }
      &__img {
        width: 440px;
        @include mq(sp){
            width: 100%;
        }
        &__main {
          height: 320px;
          @include mq(sp){
            height: auto;
            margin-bottom: 20px;
          }
        }
      }
      &__textarea {
        padding: 0 60px 0 0;
        margin: 0;
        @include mq(sp){
            padding: 0;
        }
      }
    }
    .c-box-images.-multiple-images {
      .c-copy {
        margin-top: 0;
        font-size: 2.2rem;
      }
      .c-box-images__img {
        width: 460px;
        @include mq(sp){
            width: 100%;
            margin-bottom: 20px;
        }
        .c-box-images__img__main {
          height: 320px;
          margin-left: -120px;
          @include mq(sp){
            height: auto;
            margin-left: 0;
          }
        }
        .c-box-images__img__sub {
            height: 210px;
            margin-top: -60px;
            z-index: 1;
            position: relative;
            figure{
                width: 360px;
            }
          }
      }
      .c-box-images__textarea {
        padding: 0;
        margin: 0;
        .c-desc{
            display: flex;
            flex-wrap: wrap;
            .-main{
                width: 110px;
            }
            .-desc{
                width: calc(100% - 110px);
            }
        }
      }
    }
  }
}
