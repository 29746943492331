//-----------------------
.c-desc{
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .02em;
  text-align: justify;

  @include mq(sp) {
    font-size: 1.3rem;
  }

  p{
    &:nth-child(n+2){
      margin-top: 1.5em;
    }
  }

  // color
  span.-main{
    color: palettes(main);
  }
  // decoration
  &.-hyphen{
    &:before{
      content: '―';
      color: palettes(main);
      font-weight: 400;
      margin-right: .5em;
    }
  }
}