/* ========
breadcrumbs
======== */

.o-breadcrumbs{
  background-color: palettes(main,lightest);
  padding: 4px 0;
  @include mq(sp) {
    padding: 0 20px;
  }
  .l-inner{
    >span{
      display: inline;
      font-family: $gothic;
      font-size: 1.3rem;
      @include mq(sp) {
        font-size: 1.1rem;
      }
      &:nth-child(n+2){
        &:before{
          content: '>';
          margin: 0 .4em;
          display: inline-block;
          color: palettes(main);
          transform: scale(.8,1);
        }
      }
      >span, >a{
        display: inline;
        font-weight: 500;
        letter-spacing: .02em;
        line-height: 1.4;
      }
      >a{
        color: palettes(main);
        text-decoration: underline;
      }
    }
    br{
      display: none;
    }
  }

}