.c-lp-caption {
  position: relative;
  width: 220px;
  flex-shrink: 0;
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 82px;
    right: -16px;
    width: 20px;
    height: 16px;
    background: transparent url(../images/common/ic-arrow.svg) center center no-repeat;
    transform: translateX(100%);
  }
  &__num {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1;
    border: 2px solid #CAC1BB;
  }
  &:nth-last-of-type(even) .c-lp-caption__num {
    background: $color_main;
  }
  &:nth-last-of-type(odd) .c-lp-caption__num {
    background: $color_pink;
  }
  &__img {
    width: 100%;
    border: 4px solid #fff;
    margin: 0 0 17px;
    // aspect-ratio: 220 / 165;
    @include aspect-ratio(220, 165);
    img {
      object-fit: cover;
      object-position: center center;
    }
  }
  &__copy {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.9;
    padding: 0 0 17px;
    margin: 0 0 .8em;
    border-bottom: 1px solid currentColor;
  }
  &__desc {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 2;
    text-align: justify;
    word-break: break-all;
  }
}