//-----------------------
.c-box-caption{
  width: 460px;
  @include mq(sp) {
    width: 100%;
  }
  &__img{
    background: none;
    @include mq(sp) {
      background: transparent center center no-repeat;
      background-size: cover;
      padding: 78% 0 0;
      img{
        display: none;
      }
    }
  }
  &__img2{
    position: absolute;
    width: 330px;
    left: -40px;
    bottom: 0;
    transform: translate(-100%,0);
  }
  &__textarea{
    width: 460px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: 100%;
      margin: -50px 0 0;
      background-color: #fff;
      padding: 30px;
    }
    &:before{
      content: '';
      position: absolute;
      width: 420px;
      top: -50px;
      left: -50px;
      right: -50px;
      bottom: 0;
      background-color: #fff;
      z-index: -1;
      @include mq(sp) {
        content: none;
      }
    }
    .c-ttl-item{
      @include mq(sp) {
        margin: 0 -20px 0 0;
      }
    }
    &__inner{
      padding: 24px 0 0 20px;
      @include mq(sp) {
        padding: 20px 0 0;
      }
    }
    .c-copy{
      margin: 0 0 4px;
    }
    .c-btn{
      margin: 32px 0 0;
      @include mq(sp) {
        margin: 24px auto 0;
      }
    }
  }

  // type
  &.-full{
    width: auto;
    .c-box-caption__img{
      width: 100vw;
      min-width: $pc-min-width;
      height: 680px;
      position: relative;
      z-index: 0;
      margin: 0 0 0 50%;
      transform: translate(-50%, 0);
      background: transparent center center no-repeat;
      background-size: cover;
      @include mq(sp) {
        width: auto;
        height: 340px;
        min-width: $sp-min-width;
        margin: 0 -20px;
        transform: none;
        img{
          display: none;
        }
      }
    }
    .c-box-caption__textarea{
      margin-left: auto;
      &:before{
        width: auto;
      }
    }
  }

  &.-row{
    @include mq(pc) {
      width: 100%;
      display: flex;
      align-items: flex-start;
      .c-box-caption__img{
        width: 100%;
        margin: 0 0 0 -50px;
      }
      .c-box-caption__textarea{
        width: 500px;
        flex: 0 0 auto;
        padding: 0 60px;
      }
    }
    @include mq(sp) {
      .c-box-caption__img{
        transform: translate(-20px,0);
      }
      .c-box-caption__textarea{
        margin: 0;
        &:before{
          content: none;
        }
      }
    }
  }

  &.-rowrev{
    @include mq(pc) {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      .c-box-caption__img{
        width: 100%;
        margin: 0 -50px 0 0;
      }
      .c-box-caption__textarea{
        width: 500px;
        flex: 0 0 auto;
        padding: 0 60px;
      }
    }
    @include mq(sp) {
      .c-box-caption__img{
        transform: translate(20px,0);
      }
      .c-box-caption__textarea{
        margin: 0;
        &:before{
          content: none;
        }
      }
    }
  }

  &.-nobg{
    .c-box-caption__textarea{
      &:before{
        content: none;
      }
    }
  }
}