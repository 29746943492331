/* ========
lp-header
======== */

.lp-header {
  padding: 18px 0 20px;
  background-color: #fff;
  @include mq(sp) {
    padding: 10px 80px 10px 0;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .c-logo {
    width: 246px;
    height: 48px;
    @include mq(sp) {
      width: 123px;
      height: 24px;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      .iconSvg {
        svg {
          .text {
            fill: $color_text;
          }
        }
      }
    }
  }
  &__text {
    font-size: 1.2rem;
    letter-spacing: .05em;
    font-weight: 500;
    text-align: center;
    margin: 6px 0 0;
    @include mq(sp) {
      line-height: 1.4;
      font-size: 1rem;
    }
    h1 {
      display: inline;
    }
  }
}