/* ========
nav-menu
======== */

.o-nav-menu{
  display: flex;
  justify-content: center;
  padding: 88px 0 64px;
  margin: 88px 0 0;
  &__intro{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
  }
  .c-ttl-section{
    &__ja{
      color: #323232;
    }
  }
  .c-btn{
    width: 220px;
    margin: 0 auto;
    a{
      font-size: 1.4rem;
    }
  }
  &__intro{
    margin: 0 64px 0 0;
  }
  &__content{
    display: flex;
    &.-col3{
      flex-wrap: wrap;
      width: 1056px;
      .c-box-link{
        width: 320px;
        margin: 0 16px 8px;
        &__img{
          width: 40%;
        }
        &__ttl{
          font-size: 1.8rem;
        }
      }
    }
    .c-box-event{
      margin: 0 24px;
      &__textarea{
        @include mq(pc) {
          min-height: 170px;
        }
      }
    }
    .c-box-link{
      margin: 0 24px;
    }
  }
}