.c-info-unit {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__tel {
    font-family: $cormorant;
    font-size: 5.6rem;
    letter-spacing: .18em;
    border-bottom: 1px solid currentColor;
    line-height: 1;
    padding: 0 0 4px;
    @include mq(sp) {
      font-size: 3.2rem;
    }
    small {
      font-size: .4em;
      letter-spacing: .18em;
      padding: 0 .25em 0 0;
    }
    span, small {
      font-family: $gothic;
      font-weight: 400;
    }
  }
  &__time {
    font-size: 1.8rem;
    letter-spacing: .05em;
    margin: 4px 0 0;
    font-family: $gothic;
    @include mq(sp) {
      font-size: 1.6rem;
    }
    span {
      font-family: $serif;
      font-size: .88em;
      font-weight: 500;
    }
  }
}