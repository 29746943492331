/* ========
intro-wedding
======== */

.o-intro-wedding{
  background-color: #f1f6f7;
  position: relative;
  z-index: 0;
  .o-hero-caption{
    .l-inner{
      &:before{
        bottom: -80px;
      }
    }
  }
  &__content{
    position: relative;
    z-index: 1;
    padding: 178px 0 128px;
    @include mq(sp) {
      padding: 64px 0;
    }
    .l-inner{
      width: 1100px;
      @include mq(sp) {
        width: 100%;
        padding: 0 15px;
      }
    }
    .c-box-images{
      &:nth-child(n+2){
        margin: 160px 0 0;
        @include mq(sp) {
          margin: 64px 0 0;
        }
      }
    }
  }
}