//-----------------------
.c-modal-calendar{
  opacity: 0;
  pointer-events: none;
  transition: 1s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1201;
  margin: 0 auto;
  width: 100%;
  background-color: palettes(main,thinned);
  text-align: center;
  overflow-y: scroll;
  @include mq(sp) {
    padding: 72px 15px;
  }
  &.-active{
    opacity: 1;
    pointer-events: auto;
  }
  &__content{
    max-width: 1000px;
    margin: auto; 
    @include mq(pc){
      margin-top: 54px;
    }
    @include mq(sp){
      width: 100%;
    }
  }
  .c-calendar{
    margin: 30px 0 24px;
  }
  &__ttl{
    font-size: 3.2rem;
    letter-spacing: .2em;
    color: $color_text;
    @include mq(sp){
      font-size: 2.4rem;
    }
  }
  &__desc{
    font-size: 1.6rem;
    letter-spacing: .1em;
    color: $color_text;
    @include mq(sp){
      font-size: 1.3rem;
    }
  }
  &__main{
    @include mq(pc){
      display: flex;
      justify-content: center;
      margin-top: 42px;
    }
    @include mq(sp){
      margin-top: 32px;
    }
    .c-box-fair{
      width: calc(50% - 60px);
      @include mq(sp){
        width: 100%;
        &:nth-child(n+2){
          margin-top: 56px;
        }
      }
    }
  }
  &__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 56px;
    @include mq(sp){
      flex-wrap: wrap;
      margin-top: 20px;
    }
  }
}