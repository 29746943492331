//-----------------------
.c-bg-deco02{
  $bg: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bg;
  pointer-events: none;
  z-index: -1;
  color: $bg;
  &:before,&:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  &:before{
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border: 1px solid palettes(main,light);
    z-index: 1;
    @include mq(sp) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
  &:after{
    // white border
    background: transparent no-repeat;
    background-size: 50px 50px;
    background-image:
      radial-gradient(circle at top left, currentColor 48px, palettes(main,light) 49px, transparent 50px),
      radial-gradient(circle at top right, currentColor 48px, palettes(main,light) 49px, transparent 50px),
      radial-gradient(circle at bottom right, currentColor 48px, palettes(main,light) 49px, transparent 50px),
      radial-gradient(circle at bottom left, currentColor 48px, palettes(main,light) 49px, transparent 50px),
    ;
    background-position:
      top -20px left -20px,
      top -20px right -20px,
      bottom -20px right -20px,
      bottom -20px left -20px,
    ;
    border: 20px solid currentColor;
    @include mq(sp) {
      background-size: 25px 25px;
      background-image:
        radial-gradient(circle at top left, currentColor 23px, palettes(main,light) 24px, transparent 25px),
        radial-gradient(circle at top right, currentColor 23px, palettes(main,light) 24px, transparent 25px),
        radial-gradient(circle at bottom right, currentColor 23px, palettes(main,light) 24px, transparent 25px),
        radial-gradient(circle at bottom left, currentColor 23px, palettes(main,light) 24px, transparent 25px),
      ;
      background-position:
        top -10px left -10px,
        top -10px right -10px,
        bottom -10px right -10px,
        bottom -10px left -10px,
      ;
      border: 10px solid currentColor;
    }
  }
}