.c-box-lead-contact {  
  position: relative;
  z-index: 0;
  padding: 112px 0 118px;
  background-color: #323232;
  @include mq(sp) {
    padding: 56px 20px 64px;
  }
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 863px;
    height: 863px;
    background: transparent url(../images/common/bg_texture01.png) center center no-repeat;
    background-size: contain;
    top: -290px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
    pointer-events: none;
    @include mq(sp) {
      width: 520px;
      height: 520px;
      top: -180px;
    }
  }
  &__ttl {
    color: #fff;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 24px;
    span {
      display: block;
      font-family: $cinzelD;
      font-size: 3.6rem;
      letter-spacing: .05em;
      @include mq(sp) {
        font-size: 2.8rem;
      }
    }
    small {
      display: block;
      font-size: 1.2rem;
      letter-spacing: .05em;
      font-weight: 500;
    }
  }
  &__copy {
    font-size: 1.6rem;
    color: #fff;
    font-weight: 500;
    letter-spacing: .05em;
    text-align: center;
    margin: 0 0 24px;
    @include mq(sp) {
      font-size: 1.4rem;
      padding: 0 20px;
      margin: 0 0 20px;
    }
  }
  .c-info-unit {
    color: #fff;
  }
  &__btns {
    display: flex;
    justify-content: center;
    margin: 48px 0 0;
    @include mq(sp) {
      display: block;
      margin: 32px 0 0;
    }
    .c-btn {
      margin: 0 10px;
      @include mq(sp) {
        margin: 10px auto;
      }
    }
  }
}