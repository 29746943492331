/* ========
list-plan
======== */

.o-list-plan{
  position: relative;
  z-index: 0;
  overflow: hidden;
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    top: -180px;
    right: calc(50% - 750px);
    pointer-events: none;
    @include mq(sp) {
      width: 218px;
      height: 218px;
      top: -80px;
      right: -58px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  .c-ttl-line-top{
    margin: 0 0 48px;
  }
  &__list{
    margin: 0 100px;
    padding: 0 0 40px;
    display: flex;
    flex-wrap: wrap;
    @include mq(sp) {
      margin: 0;
      padding: 0 20px 40px;
    }
    .c-item-plan{
      @include mq(pc) {
        margin: 0 40px 40px 0;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
      @include mq(sp) {
        &:nth-child(n+2){
          margin: 32px 0 0;
        }
      }
    }
  }

  // color
  &.-invert{
    background-color: palettes(main,lightest);
    .o-list-plan__deco{
      left: calc(50% - 750px);
      right: auto;
      svg{
        fill: #fff;
      }
    }
    .c-item-plan{
      &__textarea{
        &:before{
          background-color: #fff;
        }
      }
    }
  }
}