/* ========
intro-bouquet
======== */

.o-intro-bouquet{
    background-color: #f1f6f7;
    position: relative;
    z-index: 0;
    padding-bottom: 120px;
    @include mq(sp) {
      padding-bottom: 64px;
    }
    .l-inner{
      padding-top: 0;
      &:before{
        bottom: -140px;
      }
    }
  
    &__content{
      background-color: #f1f6f7;
      margin-top: 60px;
      .l-inner{
        max-width: 1000px;
      }
      .c-box-images{
          margin-bottom: 10px;
          @include mq(sp){
            margin-bottom: 4px;
          }
          &__img{
            width: 100%;
            display: flex;
            &__main{
                width: 590px;
                height: 540px;
                @include mq(sp){
                  width: calc(59% - 2px);
                  height: auto;
                  margin:0 1px;
                  &:before{
                    padding: 93.5% 0 0 0;
                  }
                }
            }
            &__sub{
                width: 400px;
                margin: 0 0 0 auto;
                @include mq(sp){
                  width: calc(41% - 2px);
                }
                figure{
                    width: 100%;
                    margin: 0;
                    @include mq(sp){
                      &:before{
                        padding: 135% 0 0 0;
                      }
                    }
                }
            }
          }
          &.-rev{
            .c-box-images__img{
                width: 570px;
                @include mq(sp){
                  width: 100%;
                }
            }
            .c-box-images__textarea{
                margin-top: 0;
                padding: 60px 60px 0 60px;
                @include mq(sp){
                  padding: 20px 20px 40px 20px;
                }
                &:before{
                    width: calc(100% - 10px);
                    left: 0;
                    right: 0;
                    height: 370px;
                    @include mq(sp){
                      width: 100%;
                      height: auto;
                    }
                }
            }
            .c-box-images__img__main{
                height: 370px;
                @include mq(sp){
                  width: 100%;
                  height: auto;
                  &:before{
                    padding: 65% 0 0 0;
                  }
                }
            }
          }
          &.-grid_list{
            margin: 4px -5px 0;
            @include mq(sp){
              margin: 2px -4px 0;
            }
            .c-box-images__img__main{
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                figure{
                    width: calc(33.33% - 10px);
                    margin: 0 5px;
                    position: static;
                    height: 440px;
                    @include mq(sp){
                      width: calc(33.33% - 4px);
                      margin: 0 2px;
                      height: auto;
                      &:before{
                        content: "";
                        display: block;
                        padding:135% 0 0 0;
                      }
                    }
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3){
                        margin-bottom: 10px;
                        @include mq(sp){
                          margin-bottom: 4px;
                        }
                    }
                }
                @include mq(sp){
                  &:before{
                    display: none;
                  }
                }
            }
          }
      }
    }
}