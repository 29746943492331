/* ========
intro-plan
======== */

.o-intro-plan{
  background-color: palettes(main,lightest);
  padding: 0 0 100px;
  .o-hero-caption{
    .l-inner{
      &:before{
        bottom: -80px;
      }
    }
  }
  &__content{
    position: relative;
    z-index: 0;
    width: 1100px;
    margin: 140px auto 0px;
    padding: 80px 0;
    @include mq(sp) {
      width: 100%;
      padding: 56px 0;
      margin: 56px 0 0;
    }
    .c-bg-deco01{
      left: 50px;
      right: 50px;
      @include mq(sp) {
        left: 10px;
        right: 10px;
      }
    }
    >.c-ttl-item{
      margin: 0 0 40px;
    }
  }
  &__list{
    .c-item-shift-box{
      @include mq(pc) {
        &:nth-child(n+2){
          margin: 60px 0 0;
        }
      }
      @include mq(sp) {
        margin: 0 32px;
        &:nth-child(n+2){
          margin-top: 32px;
        }
      }
      &__textarea{
        &:after{
          background-image: url(../images/common/img_label02.png);
        }
      }
    }
  }
}