/* ========
unit-page-pickup
======== */

.o-unit-page-pickup{
  background-color: palettes(main,lightest);
  padding: 80px 0;
  @include mq(sp) {
    padding: 60px 0;
  }
  &__list{
    display: flex;
    justify-content: center;
    gap: 50px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      display: block;
      padding: 0 20px;
    }
    .c-item-pagelink{
      margin-right: 0;
      @include mq(sp) {
        &:nth-child(n+2){
          margin: 20px 0 0;
        }
      }
    }
  }
  .c-slider-pc{
    &__arrow{
      width: 50px;
      height: 50px;
      background-color: #000;
      position: absolute;
      background: transparent center center no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 16 28'%3E%3Cdefs/%3E%3Cpath fill='%2398806e' d='M16 0h-2L0 14l14 14h2L2 14z'/%3E%3C/svg%3E");
      background-size: 16px 28px;
      cursor: pointer;
      z-index: 1;
      &.-prev{
        top: 50%;
        left: 40px;
        transform: translate(0,-50%);
      }
      &.-next{
        top: 50%;
        right: 40px;
        transform: translate(0,-50%)scale(-1,1);
      }
    }
  }
}