/* ========
archive-fair
======== */

.o-archive-fair{
  &__head{
    padding: 64px 100px 40px;
    @include mq(sp) {
      padding: 40px 20px;
    }
    .c-ttl-small{
      margin: 0 0 16px;
    }
    .c-copy-small{
      margin: 0 0 32px;
    }
  }
  &__sort{
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      display: block;
    }
  }
  &__btns{
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      margin: 40px 0 0;
    }
    .c-btn-items{
      margin: 0 0 0 20px;
      @include mq(sp) {
        margin: 0;
        width: calc(50% - 8px);
      }
    }
  }
  &__content{
    background-color: palettes(main,lightest);
    padding: 80px 0 100px;
    @include mq(sp) {
      padding: 40px 0 50px;
    }
    .l-inner{
      padding: 0 100px;
      @include mq(sp) {
        padding: 0 30px;
      }
    }
    .c-item-not-found{
      display: none;
      .c-btn{
        margin-top: 16px;
      }
    }
    .-not-found{
      &.-all, &.-weekdays, &.-holiday, &.-night{
        .-all, .-weekdays, .-holiday, .-night{
          display: flex;
        }
      }
      &.-date, &.-diagnosis{
        .-date, .-diagnosis{
          display: flex;
        }
      }
      // &:before{
      //   content: 'お探しのフェアは見つかりませんでした。';
      //   display: block;
      //   font-size: 1.5rem;
      //   text-align: center;
      //   letter-spacing: .04em;
      // }
    }
    .c-item-fair{
      margin: 0 0 40px;
    }
    .c-btn{
      margin: 80px auto 0;
      @include mq(sp) {
        margin: 40px auto 0;
      }
    }
  }
}