/* ========
recommend-fair
======== */

.o-recommend-fair{
  .l-inner{
    width: 1000px;
    padding: 64px 0 88px;
    @include mq(sp) {
      width: 100%;
      padding: 40px 15px;
      position: relative;
    }
    > .c-ttl-item{
      text-align: center;
      + .c-desc{
        text-align: center;
      }
    }
  }
  &__scrollable{
    position: relative;
    z-index: 0;
    @include mq(sp) {
      &:before{
        content: 'Swipe';
        display: block;
        position: absolute;
        font-family: $roboto;
        font-size: 1.1rem;
        letter-spacing: .05em;
        font-weight: 400;
        left: 5px;
        top: 76px;
        transform: rotate(90deg);
        pointer-events: none;
        z-index: 2;
      }
      &:after{
        content: '';
        width: 20px;
        height: 1px;
        background-color: palettes(main,light);
        display: block;
        position: absolute;
        left: -15px;
        top: 86px;
        pointer-events: none;
        z-index: 2;
      }
    }
  }
  &__content{
    display: flex;
    // justify-content: space-between;
    margin: 48px 0 0;
    @include mq(sp) {
      position: relative;
      padding: 0 48px;
      margin: 32px -15px 0;
      overflow-x: scroll;
    }
  }
  &__item{
    width: 300px;
    margin-right: 50px;
    @include mq(pc) {
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include mq(sp) {
      flex: 0 0 auto;
      width: 240px;
      margin: 0 15px;
      &:last-child{
        padding-right: 40px;
        box-sizing: content-box;
      }
    }
    a{
      display: block;
      @include mq(pc) {
        &:hover{
          .o-recommend-fair__img{
            figure{
              transform: scale(1.06);
            }
          }
        }
      }
    }
    .c-copy{
      line-height: 1.6;
    }
  }
  &__img{
    margin: 0 0 8px;
    overflow: hidden;
    figure{
      width: 100%;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
      &:before{
        content: '';
        display: block;
        padding: 80% 0 0;
      }
      img{
        display: none;
      }
    }
  }
}