///*------------------------------
// mixin
// -------------------------------*/

// media query
@mixin mq($DEVICE) {
  @if $DEVICE == "pc" {
      @media (min-width: $bp-sp + 1) {
          @content;
      }
  }
  @else if $DEVICE == "sp" {
      @media (max-width: ($bp-sp)) {
          @content;
      }
  }
}


// color palettes
@function palettes($key, $tone: base) {
  @return map-get( map-get($palettes, $key), $tone);
}

@mixin aspect-ratio($width, $height, $max-width: none) {
  position: relative;
  @if ($max-width != 'none') {
    max-width: $max-width + px;
  }
  &::before {
    float: left;
    content: "";
    padding-top: round(($height / $width) * 100% * 100) / 100;
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
  > img, > svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  > img {
    object-fit: cover;
  }
}

/*------------------------------
表示・非表示切替
-------------------------------*/
.pc{
  @include mq(sp){
    display: none !important;
  }
}
.sp{
  @include mq(pc){
    display: none !important;
  }
}