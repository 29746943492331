/* ========
list-propose-service
======== */
.o-list-propose-service {
  background-color: palettes(main, lightest);
  padding: 80px 0 100px;
  @include mq(sp) {
    padding: 40px 0 80px;
  }
  .l-inner {
    width: 1000px;
    padding: 70px 90px 30px 90px;
    background-color: #fff;
    @include mq(sp) {
      width: 100%;
      padding:35px 20px;
    }
  }
  .c-copy-basic{
    text-align: center;
    margin-bottom: 60px;
    font-size: 1.6rem;
    @include mq(sp) {
      font-size: 1.4rem;
      margin-bottom: 30px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -50px;
    margin-right: -50px;
    @include mq(sp) {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
    &.-row-rev{
      margin-bottom: 80px;
      .c-box-simple {
        width: 100%;
        a{
          display: flex;
          flex-wrap: wrap;
          flex-direction: row-reverse;
          @include mq(sp){
            flex-direction: column;
          }
          &:before{
            top: 130px;
            right: -10px;
            left: auto;
            @include mq(sp){
              top: -10px;
              right: auto;
              left: -10px;
            }
          }
        }
        &__img{
          width: 360px;
          height: 240px;
          @include mq(sp){
            width: 100%;
            height: auto;
          }
          &:before{
            padding: 66.66% 0 0 0;
          }
        }
        &__textarea{
          width: calc(100% - 360px);
          padding-right: 50px;
          padding-top: 0;
          padding-bottom: 0;
          @include mq(sp){
            width: 100%;
            padding-right: 0;
            padding-top: 50px;
          }
          &:before{
            top: -10px;
            right: -110px;
            left: auto;
            @include mq(sp){
              top: -110px;
              right: -10px;
            }
          }
          .c-box-simple__ttl{
            text-align: left;
            color: $color_text;
            font-size: 2.3rem;
            letter-spacing: .02em;
            @include mq(sp){
              font-size: 2rem;
            }
          }
          .c-btn{
            width: 100%;
          }
        }
      }
    }
    .c-box-simple {
      width: calc(50% - 100px);
      margin: 0 50px;
      @include mq(sp) {
        width: 100%;
        margin: 0;
      }
      a{
        &:before{
          width: 120px;
          height: 120px;
          content: '';
          display: block;
          top: -10px;
          right: 0;
          left: -10px;
          bottom: 0;
          position: absolute;
          z-index: -1;
          background-color: #cac1bb;
        }
      }
      &__ttl {
        font-family: $cinzelD;
        color: palettes(main);
        font-size: 2.4rem;
        line-height: 1.4;
        font-weight: 400;
        letter-spacing: 0.12em;
        margin: 0 0 4px;
        text-align: center;
        @include mq(sp) {
          font-size: 2rem;
        }
      }
      &__copy{
        margin-bottom: 20px;
        text-align: center;
        display: block;
        font-size: 1.4rem;
      }
      &__textarea {
        min-height: auto;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        padding-top: 50px;
        padding-bottom: 40px;
        position: relative;
        &:before{
          width: 120px;
          height: 120px;
          content: '';
          display: block;
          top: -110px;
          left: auto;
          right: -10px;
          bottom: 0;
          position: absolute;
          z-index: -1;
          background-color: #cac1bb;
        }
        .c-btn {
          margin:40px auto 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: palettes(main);
          font-family: $cormorant;
          font-size: 1.6rem;
          letter-spacing: 0.05em;
          font-weight: 400;
          color: #fff;
          position: relative;
          z-index: 0;
          transition: color 0.4s ease;
          @include mq(pc) {
            &:hover {
              color: palettes(main);
              .iconSvg {
                svg {
                  fill: palettes(main);
                }
              }
              .itemBg {
                figure {
                  transform: scale(1, 1);
                }
              }
            }
          }
          @include mq(sp) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
