//-----------------------
.c-box-plan{
  &__ttl{
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: .2em;
    color: $color_main;
    border-bottom: 1px solid $color_main;
    margin-top: 36px;
    @include mq(sp){
      font-size: 1.8rem;
      margin-top: 24px;
      padding-left: 8px;
    }
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    margin-top: 28px;
    @include mq(sp){
      margin-top: 12px;
    }
    .c-box-plan__item{
      font-size: 1.5rem;
      line-height: 2.2;
      letter-spacing: .02em;
      color: $color_link;
      display: flex;
      align-items: center;
      width: 50%;
      @include mq(sp){
        width: 100%;
        font-size: 1.3rem;
      }
      &:before{
        content: "・";
        display: block;
        margin-right: 4px;
      }
    }
  }
}