/* ========
archive-report
======== */

.o-archive-report{
  background-color: #f1f6f7;
  &__content{
    padding: 120px 0 100px;
    @include mq(sp) {
      width: calc(100% - 30px);
      padding: 64px 0;
      margin: 0 auto;
    }
  }
  &__head{
    padding: 64px 0 88px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 32px 0;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: palettes(blue,lightest);
      pointer-events: none;
    }
    .iconSvg{
      display: inline-block;
      width: 42px;
      height: 42px;
      svg{
        fill: #98806e;
      }
    }
  }
  .c-box-report:nth-child(n+2){
    margin-top: 148px;
    @include mq(sp){
      margin-top: 64px;
    }
  }
  .c-item-pager.-numbers{
    margin-top: 88px;
    @include mq(sp){
      margin-top: 64px;
    }
  }
}