/* ========
intro-service
======== */

.o-intro-service{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    bottom: 20px;
    right: calc(50% - 750px);
    pointer-events: none;
    @include mq(sp) {
      width: 218px;
      height: 218px;
      bottom: 20px;
      right: -58px;
    }
    svg{
      fill: #fff;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
    margin: 0 auto;
    padding: 0 0 100px;
    @include mq(sp) {
      width: 100%;
      padding: 64px 20px;
    }
    .c-box-simple{
      @include mq(pc) {
        margin: 100px 45px 0 0;
        &:nth-child(3n){
          margin-right: 10px;
        }
      }
      @include mq(sp) {
        &:nth-child(n+2){
          margin: 64px 0 0;
        }
      }
    }
  }
}