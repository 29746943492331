/* ========
l-wrapper
======== */
.l-wrapper {
  position: relative;
  overflow: hidden;
  min-width: $pc-min-width;
  padding: 124px 0 0;
  @include mq(sp) {
    min-width: $sp-min-width;
    padding: 64px 0 0;
  }
}


// color
.-blue{
  .o-breadcrumbs{
    background-color: #f1f6f7;
  }
  .o-hero-caption{
    .l-inner{
      &:before{
        background-color: #dce9ec;
      }
    }
  }
}