//-----------------------
.c-box-deco-caption{
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-start;
  padding: 80px;
  @include mq(sp) {
    display: block;
    padding: 32px 32px 40px;
  }
  &__img{
    width: 380px;
    flex: 0 0 auto;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: 100%;
    }
    &:before{
      content: '';
      display: block;
      padding: 73.68% 0 0;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
    }
    img{
      display: none;
    }
  }
  &__textarea{
    margin: 0 0 0 64px;
    @include mq(sp) {
      margin: 32px 0 0;
    }
  }
  .c-ttl-middle{
    color: palettes(main);
    letter-spacing: .1em;
    line-height: 1.2;
    margin: 0 0 2px;
    & + .c-desc{
      margin: 0 0 24px;
      @include mq(sp) {
        margin: 0 0 16px;
      }
    }
  }
  .c-btn{
    margin: 40px 0 0;
    @include mq(sp) {
      margin: 24px 0 0;
    }
  }
}