/* ========
list-access
======== */
.gmap {
  position: relative;
  width: 100%;
  padding-top: 500px;
  @include mq(sp){
    padding-top: 300px;
  }
}
#gmap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-list-access {
  background-color: #f4f3f1;
  // padding: 0 0 100px;
  .c-ttl-section{
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  &__content {
    position: relative;
    padding: 60px 0 40px 0;
    @include mq(sp) {
      padding: 30px 0 0 0;
    }
    .c-box-images {
      &__textarea {
        margin-top: 50px;
        padding: 60px 60px 100px 60px;
        width: calc(100% - 550px);
        @include mq(sp){
          margin-top: 20px;
          width: 100%;
          padding: 30px;
          background-color: #fff;
          &::before{
            display: none;
          }
        }
        .c-btn{
          width: 420px;
          @include mq(sp){
            width: 100%;
            left: 0;
          }
          a{
            font-size: 1.4rem;
            @include mq(sp){
              font-size: 1.2rem;
            }
          }
        }
      }
      &__img__main {
        height: 340px;
      }
    }
    .c-ttl {
      font-size: 2.4rem;
      letter-spacing: 0.02em;
      line-height: 1.25;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $cormorant;
        color: $color_main;
        border: 1px solid $color_main;
        width: 30px;
        height: 30px;
        font-size: 2.2rem;
        margin-left: 15px;
      }
    }
    .c-desc.-addr{
      margin-bottom: 10px;
    }
    .c-desc {
      display: flex;
      flex-wrap: wrap;
      .-main {
        width: 100px;
      }
    }
  }
  .l-inner {
    max-width: 1000px;
    position: relative;
    z-index: 0;
    padding: 16px 0 56px;
    @include mq(sp) {
      width: 100%;
      padding: 0 15px;
    }
    .c-box-row {
      margin-left: -20px;
      margin-right: -20px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      @include mq(sp) {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
      }
    }
    .c-box {
      background-color: #fff;
      width: 100%;
      margin: 20px;
      padding: 45px 60px 30px 60px;
      @include mq(sp) {
        margin: 0;
        padding: 30px;
      }
      &.-col2 {
        width: calc(50% - 40px);
        margin: 20px;
        @include mq(sp) {
          width: 100%;
          margin: 20px 0;
          &:last-child{
            margin-top: 0;
          }
        }
        .c-ttl {
          font-size: 2.4rem;
          letter-spacing: 0.02em;
          line-height: 1.25;
          margin-bottom: 35px;
          display: flex;
          align-items: center;
          .-label {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $cormorant;
            color: $color_main;
            border: 1px solid $color_main;
            width: 30px;
            height: 30px;
            font-size: 2.2rem;
            margin-left: 15px;
          }
        }
        .c-desc {
          &.-addr {
            margin-bottom: 15px;
          }
          &.-data {
            display: flex;
            .-main {
              width: 80px;
            }
          }
          &.-note {
            font-size: 1.3rem;
            color: #a1a1a1;
            padding-top: 15px;
            // margin-bottom: 20px;
          }
        }
        .c-btn {
          height: 36px;
          @include mq(sp){
            left: 0;
            right: 0;
            bottom: 0;
          }
          a {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.o-access-info {
  padding-bottom: 70px;
  .l-inner {
    max-width: 1000px;
  }
  .c-ttl-section {
    padding-top: 70px;
    padding-bottom: 30px;
    @include mq(sp){
      padding-top: 30px;
      padding-bottom: 15px;
    }
    .c-ttl {
      font-size: 2.8rem;
      line-height: 1.42;
      letter-spacing: 0.2em;
      @include mq(sp){
        font-size: 2.2rem;
      }
    }
  }
  .c-box-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    @include mq(sp){
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
    .c-box.-col3 {
      width: calc(33.33% - 40px);
      @include mq(sp){
        width: 100%;
      }
    }
    .c-box.-col2 {
      width: calc(50% - 40px);
      @include mq(sp){
        width: 100%;
      }
    }
    .c-box {
      width: calc(100% - 40px);
      margin: 20px;
      @include mq(sp){
        width: 100%;
        margin: 0;
        padding: 20px;
      }
      .c-ttl {
        color: $color_main;
        font-size: 2rem;
        letter-spacing: 0.1em;
        border-bottom: 1px solid $color_main;
        margin-bottom: 20px;
      }
      .c-desc {
        font-size: 1.6rem;
        letter-spacing: 0.02em;
        line-height: 1;
        color: $color_main;
        border-left: 2px solid $color_main;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      .c-list {
        margin-bottom: 30px;
        li {
          font-size: 1.4rem;
          letter-spacing: 0.02em;
          line-height: 1.5;
          padding-left: 15px;
          position: relative;
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
          &:before {
            background-color: $color_main;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 2px;
            display: block;
            position: absolute;
            left: 0;
            top: 8px;
          }
        }
      }
    }
    .-text{
      font-size: 1.4rem;
    }
  }
  .-divider{
    border-top: 1px solid #f4f3f1;
    margin: 60px 0 0 0;
  }
  .-btn{
    width: 470px;
    margin:0 auto;
    @include mq(sp){
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
      a{
        text-align: center;
        display: block;
        width: 100%;
        color: #98806e;
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 0.1em;
        border-top: 1px solid #98806e;
        border-bottom: 1px solid #98806e;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq(sp){
          font-size: 1.2rem;
        }
        .ic-arrow{
          display: block;
          width: 8px;
          height: 8px;
          border-top: solid 1px #98806e;
          border-right: solid 1px #98806e;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          margin-left: 10px;
        }
      }
  }
}

.o-block-blue {
  background-color: #dce9ec;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  padding: 30px 60px;
  @include mq(sp){
    margin: 20px;
    flex-direction: column;
    padding: 20px;
  }
  &:before {
    content: "";
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 1px solid #fff;
    position: absolute;
    left: 4px;
    top: 4px;
  }
  &__img {
    width: 310px;
    flex: 0 0 auto;
    margin-top: -50px;
    margin-right: -80px;
    position: relative;
    z-index: 1;
    @include mq(sp){
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 40px;
    }
    figure {
      background: transparent center center no-repeat;
      background-size: cover;
      height: 190px;
    }
    img {
      display: none;
    }
  }
  &__textarea {
    color: $color_main;
    margin-right: auto;
    @include mq(sp){
      margin-right: 0;
    }
    .c-ttl {
      font-size: 3rem;
      letter-spacing: 0.1em;
      line-height: 1;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @include mq(sp){
        font-size: 2.4rem;
      }
      .-sub {
        background-color: $color_pink;
        color: #fff;
        border-radius: 13px;
        font-size: 1.6rem;
        letter-spacing: 0.02em;
        line-height: 1;
        padding: 5px 10px;
        margin-left: 10px;
        @include mq(sp){
          font-size: 1.3rem;
        }
      }
    }
    .traffic {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @include mq(sp){
        flex-direction: column;
      }
      &__item {
        font-size: 2rem;
        line-height: 1;
        background-color: #fff;
        letter-spacing: 0.02em;
        padding: 12px 30px;
        position: relative;
        @include mq(sp){
          width: 100%;
          text-align: center;
        }
        .traffic__item__deco {
          position: absolute;
          right: 0;
          top: -40px;
          @include mq(sp){
            top: -30px;
          }
          img {
            height: 40px;
            @include mq(sp){
              height: 30px;
            }
          }
        }
      }
      &__ic {
        width: 36px;
        height: 26px;
        margin-left: 10px;
        margin-right: 10px;
        @include mq(sp){
          transform: rotate(-90deg);
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .c-desc {
      font-size: 1.6rem;
      @include mq(sp){
        font-size: 1.3rem;
        line-height: 1.5em;
      }
    }
  }
}
