.c-lp-fair {
  // background-color: palettes(main,lightest);
  background-color: palettes(blue,lightest);
  padding: 94px 0 90px;
  @include mq(sp) {
    padding: 60px 0;
  }
  &__ttl {
    font-size: 3.6rem;
    text-align: center;
    line-height: 1.4;
    margin: 0 0 40px;
    @include mq(sp) {
      font-size: 2.8rem;
    }
    span {
      font-family: $cinzelD;
      letter-spacing: .05em;
      font-weight: 400;
      display: block;
    }
    small {
      font-size: 1.2rem;
      letter-spacing: .05em;
      font-weight: 500;
      display: block;
    }
  }
  &__content {
    display: flex;
    gap:  52px;
    overflow: auto;
    margin: 0 -38px;
    padding: 20px 20px 40px;
    @include mq(sp) {
      margin: 0;
      padding-inline: 40px;
    }
  }
}