//-----------------------
.c-item-shop{
  width: 480px;
  @include mq(sp) {
    width: 100%;
  }
  a{
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:hover{
        .c-item-plan__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    @include mq(sp) {
    }
  }
  &__img{
    position: relative;
    z-index: 0;
    flex: 0 0 auto;
    width: 240px;
    height: 190px;
    overflow: hidden;
    margin: 40px 0;
    @include mq(sp) {
      width: 120px;
      height: 120px;
      margin: 24px 0;
      &:before{
        content: '';
        display: block;
        padding: 73.33% 0 0;
      }
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
    img{
      display: none;
    }
  }
  &__textarea{
    padding: 40px 40px 40px 30px;
    flex: 0 0 auto;
    @include mq(sp) {
      padding: 24px 24px 40px;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 40px;
      right: 0;
      bottom: 0;
      background-color: #fff;
      pointer-events: none;
      @include mq(sp) {
        left: 32px;
      }
    }
  }
  .c-copy-basic{
    // font-family: $cinzelD;
    color: palettes(main);
    line-height: 1.5;
    letter-spacing: .05em;
    margin: 0 0 8px;
  }
  &__ttl{
    // font-family: $cinzelD;
    font-weight: 400;
    font-size: 3.2rem;
    letter-spacing: .1em;
    color: palettes(main);
    line-height: 1.1;
    margin: 0 0 8px;
  }
  .c-desc{
    line-height: 1.8;
  }
  &__more{
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: palettes(main);
    color: #fff;
    width: 92px;
    height: 30px;
    font-family: $cormorant;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0 0;
    @include mq(sp) {
      font-size: 1rem;
      width: 56px;
      height: 22px;
      padding: 0;
    }
  }
  // position: relative;
  // z-index: 0;
  // width: 300px;
  // @include mq(sp) {
  //   width: 100%;
  // }
  // a{
  //   display: block;
  //   @include mq(pc) {
  //     &:hover{
  //       .c-item-shop__img{
  //         figure{
  //           transform: scale(1.06);
  //         }
  //       }
  //     }
  //   }
  //   &:before{
  //     content: '';
  //     display: block;
  //     top: -10px;
  //     left: 50%;
  //     right: -10px;
  //     bottom: -10px;
  //     position: absolute;
  //     z-index: -1;
  //     background-color: palettes(main,light);
  //   }
  // }
  // &__img{
  //   width: 100%;
  //   position: relative;
  //   z-index: 0;
  //   overflow: hidden;
  //   &:before{
  //     content: '';
  //     display: block;
  //     padding: 73.33% 0 0;
  //   }
  //   figure{
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background: transparent center center no-repeat;
  //     background-size: cover;
  //     transition: transform 2s cubic-bezier(.12,.72,.46,1);
  //   }
  //   img{
  //     display: none;
  //   }
  // }
  // &__textarea{
  //   background-color: #fff;
  //   padding: 20px 20px 56px;
  //   min-height: 220px;
  //   @include mq(sp) {
  //     min-height: 0;
  //   }
  // }
  // &__logo{
  //   height: 69px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin: 0 0 16px;
  //   img{
  //     width: auto;
  //     height: 100%;
  //   }
  // }
  // &__ttl{
  //   font-size: 1.4rem;
  //   font-weight: 500;
  //   letter-spacing: .05em;
  //   text-align: center;
  //   line-height: 1.4;
  // }
  // &__more{
  //   font-family: $cormorant;
  //   font-size: 1.6rem;
  //   font-weight: 400;
  //   letter-spacing: .05em;
  //   color: palettes(main);
  //   line-height: 1.4;
  //   display: flex;
  //   align-items: center;
  //   position: absolute;
  //   right: 20px;
  //   bottom: 16px;
  //   .iconSvg{
  //     display: block;
  //     width: 10px;
  //     height: 10px;
  //     margin: 0 0 0 .5em;
  //   }
  //   svg{
  //     fill: palettes(main);
  //   }
  // }
}