/* ========
intro-fitting
======== */

.o-intro-fitting{
  background-color: #f1f6f7;
  position: relative;
  z-index: 0;
  padding-bottom: 120px;
  @include mq(sp) {
    padding-bottom: 64px;
  }
  .l-inner{
    padding-top: 0;
    &:before{
      bottom: -140px;
    }
  }

  &__content{
    background-color: #f1f6f7;
    .l-inner{
      max-width: 1000px;
    }
    .c-box-caption{
      margin: 80px 0 0;
      &.-full{
        @include mq(pc) {
          .c-box-caption__img{
            width: calc(100% - 80px);
            min-width: calc(100% - 80px);
            height: 400px;
            left: -40px;
            position: relative;
            z-index: 1;
          }
          .c-box-caption__img2{
            width: 310px;
            left: -40px;
            bottom: auto;
            top: 70px;
          }
          .c-box-caption__textarea{
            width: 610px;
            padding-top: 60px;
            padding-bottom: 60px;
            &:before{
              right: 0;
              left: -110px;
              width: 720px;
              z-index: -1;
            }
            .c-copy{
              padding-top: 16px;
            }
            &__inner{
              padding-right: 60px;
              padding-left: 0;
            }
          }
        }
      }
      &.-rowrev{
        @include mq(pc) {
          .c-box-caption__img{
            margin: 0;
            position: relative;
            z-index: 1;
          }
          .c-box-caption__textarea{
            width: 560px;
            padding-top: 100px;
            padding-bottom: 60px;
            &:before{
              width: 620px;
              left: 0;
              top: 50px;
            }
            .c-copy{
              padding-top: 16px;
            }
            &__inner{
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
      &.-row{
        @include mq(pc) {
          .c-box-caption__img{
            margin: 0;
            position: relative;
            z-index: 1;
          }
          .c-box-caption__textarea{
            width: 560px;
            padding-top: 100px;
            padding-bottom: 60px;
            &:before{
              width: 620px;
              left: -60px;
              top: 50px;
            }
            .c-copy{
              padding-top: 16px;
            }
            &__inner{
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

