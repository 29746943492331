/* ========
nav-bar
======== */

.o-nav-bar{
  background-color: #fff;
  height: 54px;
  @include mq(sp) {
    height: 70px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }
  &__inner{
    width: calc(50% + 600px);
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 auto;
    @include mq(sp) {
      width: 100%;
    }
  }
  .c-link-divider{
    @include mq(sp) {
      display: none;
    }
  }
  &__btns{
    display: flex;
    @include mq(sp) {
      width: 100%;
      height: 100%;
      .c-btn-icon{
        width: 100%;
        flex: 1 1 auto;
        height: 100%;
        a{
          padding: 28px 0 0;
          font-size: 1.1rem;
          letter-spacing: 0;
          text-align: center;
          line-height: 1.4;
          position: relative;
          z-index: 0;
          &:before{
            content: '';
            width: 0;
            height: 0;
            border-width: 0 0 8px 8px;
            border-style: solid;
            border-color: transparent transparent #fff transparent;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        .iconSvg{
          top: 16px;
          left: 0;
          right: 0;
          bottom: auto;
          margin: 0 auto;
        }
      }
    }
  }
}