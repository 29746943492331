/* ========
lead-brand
======== */

.o-lead-brand{
  padding: 100px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 20px;
  }
  &__content{
    max-width: 1100px;
    background-color: palettes(main,lightest);
    position: relative;
    z-index: 0;
    padding: 80px 0 72px;
    @include mq(sp) {
      padding: 40px 15px;
    }
  }
  .c-ttl-item{
    text-align: center;
    + .c-desc{
      display: block;
      text-align: center;
    }
  }
  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    top: 220px;
    right: -96px;
    pointer-events: none;
    @include mq(sp) {
      width: 143px;
      height: 218px;
      top: 140px;
      right: -18px;
    }
    svg{
      fill: #fff;
    }
  }
  &__list{
    width: 870px;
    margin: 56px auto 0;
    @include mq(sp) {
      display: flex;
      width: auto;
      margin: 32px -40px 0;
      overflow-y: scroll;
      padding: 0 40px;
    }
    .slick-list{
      overflow: visible;
    }
  }
  &__item{
    width: 280px;
    margin: 0 5px;
    cursor: pointer;
    @include mq(sp) {
      flex: 0 0 auto;
      width: 220px;
    }
  }
  &__img{
    background: transparent center center no-repeat;
    background-size: cover;
    &:before{
      content: '';
      display: block;
      padding: 114.2% 0 0;
      @include mq(sp) {
        padding: 100% 0 0;
      }
    }
    img{
      display: none;
    }
  }
  &__logo{
    width: 64%;
    height: 40px;
    background: transparent center center no-repeat;
    background-size: contain;
    margin: 16px auto 0;
    @include mq(sp) {
      margin: 8px auto 0;
    }
    img{
      display: none;
    }
    span{
      display: none;
    }
  }
}