/* ========
list-tnumb-link
======== */

.o-list-tnumb {
  padding-bottom: 80px;
  padding-top: 80px;
  &__row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  }
  &__item {
    width: 25%;
    figure{
      position: relative;
      background-size: cover;
      &:before{
        content: "";
        display: block;
        padding:49% 0 0 0;
      }
      img{
        display: none;
      }
    }
  }
  .c-ttl-section{
    margin-bottom: 40px;
  }
  .c-desc{
    font-size: 1.6rem;
    padding-top: 80px;
  }
}
