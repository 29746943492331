/* ========
list-contact
======== */
.o-list-contact {
  background-color: #f4f3f1;
  padding: 0 0 100px;
  .l-inner {
    max-width: 1000px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: 100%;
      padding: 0 15px;
    }
    .c-box-row{
      margin-left: -20px;
      margin-right: -20px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      @include mq(sp) {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
      }
    }
    .c-box{
      background-color: #fff;
      width: 100%;
      margin: 20px;
      padding: 60px;
      @include mq(sp){
        margin: 0;
        padding: 30px;
      }
      &.-huge{
        display: flex;
        flex-wrap: wrap;
        @include mq(sp){
          flex-direction: column;
        }
        .col:nth-of-type(1){
          width: 44%;
          @include mq(sp){
            width: 100%;
          }
        }
        .col:nth-of-type(2){
          width: 56%;
          @include mq(sp){
            width: 100%;
          }
          .c-desc{
            font-family: $cormorant;
            font-size: 2.6rem;
            line-height: 1;
            margin-bottom: 30px;
            .-main{
              display: block;
              font-family: $cormorant;
              font-size: 1.6rem;
              line-height: 1;
              margin-bottom: 4px;
            }
            .-note{
              font-family: $serif;
              font-size: 1.3rem;
              padding-left: 10px;
              @include mq(sp){
                display: block;
                padding-left: 0;
              }
            }
          }
        }
        .c-ttl{
          font-size: 2.8rem;
          letter-spacing: .02em;
          line-height: 1.25;
          margin-bottom: 20px;
        }
        .c-desc{
          &.-addr{
            margin-bottom: 15px;
          }
          &.-data{
            display: flex;
            .-main{
              width: 80px;
            }
          }
        }
        .c-btn-row{
          display: flex;
          flex-wrap: wrap;
          .c-btn{
            width: calc(33.33% - 2px);
            margin: 1px;
            height: 36px;
            @include mq(sp){
              width: 100%;
              margin: 0 0 4px 0;
            }
            a{
              font-size: 1.3rem;
            }
          }
          .c-btn:nth-of-type(1){
            width: 100%;
            height: 60px;
            @include mq(sp){
              height: 36px;
            }
            a{
              @include mq(pc) {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
      &.-col2{
        width: calc(50% - 40px);
        margin: 20px;
        @include mq(sp) {
          width: 100%;
          margin: 20px 0;
        }
        .c-ttl{
          font-size: 2.4rem;
          letter-spacing: .02em;
          line-height: 1.25;
          margin-bottom: 40px;
        }
        .c-desc{
          &.-addr{
            margin-bottom: 15px;
          }
          &.-data{
            display: flex;
            .-main{
              width: 80px;
            }
          }
          &.-note{
            padding-top: 20px;
            margin-bottom: 20px;
          }
        }
        .c-btn{
          height: 36px;
          margin-top: 20px;
          a{
            font-size: 1.3rem;
          }
        }
      }
      .c-desc{
        @include mq(sp) {
          text-align: left;
        }
      }
    }
  }
  .c-copy-basic {
    margin: 0 0 60px;
    text-align: center;
    font-size: 1.8rem;
    padding-top: 60px;
    @include mq(sp) {
      text-align: left;
      font-size: 1.5rem;
      padding: 30px 15px;
      margin: 0;
    }
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
