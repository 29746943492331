/* ========
detail-information
======== */

.o-detail-information{
  background: palettes(main,lightest);
  .l-inner{
    max-width: 1000px;
    padding: 80px 0 120px;
    @include mq(sp){
      padding: 40px 20px 50px;
    }
  }
  &__content{
    background: #fff;
    margin: 0 auto;
    padding: 60px;
    @include mq(sp){
      padding: 30px 20px;
    }

    .o-detail-meta{
      .c-item-information__ttl{
        font-size: 2.6rem;
        line-height: 1.07;
        @include mq(pc){
          padding: 0 0 1em;
          margin: .75em 0 0;
        }
        @include mq(sp){
          font-size: 2.2rem;
          margin: .75em 0 24px;
        }
      }
    }

    .o-detail-content{
      padding: 50px 60px 72px;
      @include mq(sp){
        padding: 0 0 24px;
      }
      figure{
        max-width: 530px;
        margin: 0 auto 42px;
        @include mq(sp){
          max-width: 100%;
          margin-bottom: 24px;
        }
      }
      p{
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: .02em;
        line-height: 1.8;
        text-align: justify;

        @include mq(sp) {
          font-size: 1.3rem;
        }
      }
    }

    .c-btn{
      margin: 0 auto;
      width: auto;
      max-width: 600px;
      @include mq(sp){
        max-width: 100%;
      }
      & + .c-btn{
        margin-top: 10px;
      }
      a{
        line-height: 1.4;
      }
    }
  }
  .c-item-pager{
    margin-top: 40px;
  }
  .o-unit-infomation{
    margin-top: 90px;
    @include mq(sp){
      margin-top: 40px;
    }
  }

  .c-textarea-forms{
    text-align: center;
    background-color: #fff;
    padding: 40px 0;
    margin: 24px 0 0;
    .c-copy{
      font-size: 1.4rem;
      line-height: 1.4;
      margin-bottom: 30px;
      font-family: $cormorant;
      @include mq(sp){
        margin-bottom: 15px;
      }
      &.-phone{
        font-size: 2.2rem;
        margin-bottom: 20px;
        .-note{
          font-size: 1.3rem;
          padding-left: 10px;
          @include mq(sp){
            position: relative;
            top: -5px;
          }
        }
        @include mq(sp){
          .-num{
            display: flex;
            justify-content: center;
          }
        }
        .-num.-sub{
          font-size: 1.8rem;
          @include mq(sp){
            font-size: 2.2rem;
          }
        }
        .-main{
          color: #98806e;
          margin-right: .5em;
          @include mq(sp){
            width: 6em;
            text-align: left;
          }
          &.-sp-short{
            @include mq(sp){
              width: auto;
            }
          }
        }
        .-slash{
          padding: 0 .5em;
          @include mq(sp){
            display: none;
          }
        }
      }
    }
  }
}