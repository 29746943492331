//-----------------------
.c-img-hero{
  height: 580px;
  background: transparent center top 15% no-repeat;
  background-size: cover;
  @include mq(sp) {
    height: 350px;
  }
  .bgSrc{
    display: none;
  }
}