//-----------------------
.c-item-not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  .c-desc{
    &.-tel{
      margin-top: 20px;
      font-size: 2.6rem;
      line-height: 1;
      .-main{
        display: block;
        font-size: 1.6rem;
        line-height: 1;
        margin-bottom: 4px;
      }
      .-note{
        font-size: 1.3rem;
        padding-left: 10px;
        @include mq(sp) {
          font-size: 1.1rem;
        }
      }
    }
  }
  .c-btn{
    margin: 0 auto;
  }
}