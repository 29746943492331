//-----------------------
.c-link-sp-accordion{
  $fontSizeLabel: 2.2;
  $fontSizeSmall: 1.4;
  $fontSizeLabelSP: 1.9;
  $fontSizeSmallSP: 1.2;
  @include mq(sp) {
    border-bottom: 1px solid #505050;
    // &:first-child{
    //   border-top: 1px solid #505050;
    // }
  }
  &__label{
    position: relative;
    z-index: 0;
    a{
      display: inline-block;
      @include mq(sp) {
        display: block;
        padding: 12px 0 8px;
      }
    }
    p{
      font-family: $cormorant;
      font-size: $fontSizeLabel+rem;
      color: palettes(main);
      font-weight: 400;
      letter-spacing: .1em;
      line-height: 1;
      @include mq(sp) {
        text-align: center;
        font-size: $fontSizeLabelSP+rem;
      }
      &:before{
        content: '▶';
        display: inline-block;
        transform: scale(#{0.6/$fontSizeLabel});
        transform-origin: left center;
        margin-right: -#{$fontSizeLabel/0.6/10}em;
        @include mq(sp) {
          content: none;
        }
      }
    }
    small{
      font-size: $fontSizeSmall+rem;
      color: #fff;
      letter-spacing: .02em;
      margin-left: 1.6rem;
      @include mq(sp) {
        text-align: center;
        display: block;
        font-size: $fontSizeSmallSP+rem;
        margin-left: 0;
      }
    }
    .-btn{
      @include mq(pc) {
        display: none;
      }
      width: 41px;
      height: 41px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto 0;
      cursor: pointer;
      &:before, &:after{
        content: '';
        display: block;
        width: 21px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: palettes(main);
        transition: .4s ease;
      }
      &:after{
        transform: rotate(90deg);
      }
      &.-active{
        &:after{
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list{
    margin-left: 1.6rem;
    @include mq(sp) {
      display: none;
      margin-left: 0;
      margin: 0 40px 16px;
    }
    li{
      line-height: 1.6;
    }
    a{
      display: inline-block;
      font-size: $fontSizeSmall+rem;
      color: #fff;
      letter-spacing: .02em;
      @include mq(sp) {
        font-size: $fontSizeSmallSP+rem;
      }
      &:before{
        content: '▶';
        display: inline-block;
        transform: scale(#{0.6/$fontSizeSmall});
        transform-origin: left center;
        margin-right: -#{$fontSizeSmall/0.6/10}em;
        color: palettes(main);
        @include mq(sp) {
          transform: scale(#{0.6/$fontSizeSmallSP});
          margin-right: -#{$fontSizeSmallSP/0.6/10}em;
        }
      }
    }
  }
}