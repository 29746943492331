/* ========
list-sns
======== */

.o-list-sns{
  display: flex;
  justify-content: center;
  &__item{
    width: 40px;
    margin: 0 48px;
    @include mq(sp) {
      margin: 0 12px;
    }
    a{
      display: block;
      @include mq(pc) {
        &:hover{
          .iconSvg{
            background-color: palettes(blue,dark);
          }
          p{
            letter-spacing: .15em;
          }
        }
      }
    }
    .iconSvg{
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: palettes(blue);
      border-radius: 50%;
      transition: .4s ease;
      svg{
        width: 20px;
        height: 20px;
        fill: #fff;
      }
      &[data-icon="blog"]{
        svg{
          width: 24px;
          height: 24px;
        }
      }
      &[data-icon="instagram"]{
        svg{
          width: 18px;
          height: 18px;
        }
      }
    }
    p{
      font-family: $cormorant;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .05em;
      text-align: center;
      color: #fff;
      display: block;
      text-align: center;
      white-space: nowrap;
      display: inline-block;
      line-height: 1;
      margin: 12px 0 0 50%;
      transform: translate(-50%,0);
      vertical-align: top;
      transition: .4s ease;
    }
  }
}