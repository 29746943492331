/* ========
lp-wrapper
======== */

.lp-wrapper {
  position: relative;
  overflow: hidden;
  min-width: $pc-min-width;
  @include mq(sp) {
    min-width: $sp-min-width;
  }

  .c-btn {
    @include mq(sp) {
      max-width: 400px;
      height: 64px;
      &.-middle {
        max-width: 600px;
        width: 100%;
      }
      a {
        font-size: 1.6rem;
      }
    }
  }
}