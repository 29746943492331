.c-lp-box {
  position: relative;
  z-index: 0;
  padding: 56px 0 96px;
  margin: -190px 0 0;
  background-color: #fff;
  overflow: hidden;
  @include mq(sp) {
    margin: -40px 20px 0;
    padding: 40px 0 64px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 922px;
    height: 922px;
    background: transparent url(../images/common/bg_texture02.png) center center no-repeat;
    background-size: contain;
    top: -440px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
    pointer-events: none;
    opacity: 0.5;
    @include mq(sp) {
      width: 600px;
      height: 600px;
      top: -270px;
    }
  }
  &__deco {
    width: 58px;
    height: 58px;
    fill: palettes(main);
    margin: 0 auto 24px;
    @include mq(sp) {
      width: 45px;
      height: 45px;
      margin: 0 auto 16px;
    }
  }
  &__ttl {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.6;
    text-align: center;
    margin: 8px 0;
    @include mq(sp) {
      font-size: 2rem;
      margin: 4px 0;
    }
  }
  &__desc {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .05em;
    text-align: center;
    margin: 8px 0;
    @include mq(sp) {
      font-size: 1.4rem;
      margin: 4px 0;
    }
  }
  &__period {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0 16px;
    @include mq(sp) {
      margin: 16px 0 12px;
    }
  }
  &__date {
    font-family: $cormorant;
    font-size: 4.2rem;
    letter-spacing: .1em;
    border-bottom: 1px solid currentColor;
    line-height: 1;
    padding: 0 0 10px;
    @include mq(sp) {
      font-size: 3.2rem;
    }
    small {
      font-size: .5em;
      letter-spacing: .1em;
    }
  }
  &__time {
    font-size: 1.6rem;
    letter-spacing: .05em;
    margin: 4px 0 0;
    font-family: $gothic;
    @include mq(sp) {
      font-size: 1.4rem;
    }
  }
  &__content {
    max-width: 526px;
    background-color: palettes(main,lightest);
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.8;
    text-align: justify;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    margin: 40px auto;
    @include mq(sp) {
      width: 100%;
      font-size: 1.4rem;
      margin: 24px auto;
    }
  }
  &__price {
    font-size: 4.4rem;
    font-family: $cinzelD;
    letter-spacing: .05em;
    color: palettes(main);
    text-align: center;
    line-height: 1.2;
    margin: 0 0 56px;
    @include mq(sp) {
      font-size: 3.6rem;
      margin: 0 0 32px;;
    }
    small {
      font-size: .5em;
      letter-spacing: .05em;
    }
  }
  &__btns {
    display: flex;
    justify-content: center;
    gap: 20px;
    @include mq(sp) {
      flex-direction: column;
      gap: 10px;
    }
  }
  .c-btn {
    .iconSvg:not([data-icon="arrow"]) {
      position: initial;
      display: block;
      width: 1.25em;
      height: 1.25em;
      margin: 0 .5em 0 0;
    }
  }
}