//-----------------------
.c-ttl-underline {
  font-size: 2.8rem;
  letter-spacing: 0.2em;
  font-weight: 500;
  padding-bottom: 4px;
  margin-bottom: 40px;
  border-bottom: 1px solid #98806e;
  .-price{
    font-family: $cormorant;
    color: #98806e;
    letter-spacing: 0.02em;
    font-size: 3.4rem;
    font-weight: 400;
    padding-left: 1em;
  }
  small{
    font-size: 1.4rem;
    letter-spacing: 0.02em;
  }
  @include mq(sp) {
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
  }
}
