.o-list-faq {
  background-color: #f4f3f1;
  padding: 0 0 100px;
  .l-inner {
    width: 1000px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: 100%;
      padding: 0 15px;
    }
  }
  .c-copy-basic {
    margin: 0 0 60px;
    text-align: center;
    font-size: 1.8rem;
    padding-top: 60px;
    @include mq(sp) {
      text-align: left;
      font-size: 1.5rem;
      padding: 30px 15px;
      margin: 0;
    }
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.list-tgl {
    margin-bottom: 40px;
  &__item {
    margin-bottom: 4px;
    &__link {
      padding: 12px 44px 12px 70px;
      font-size: 1.8rem;
      letter-spacing: 0.02em;
      display: block;
      background-color: #fff;
      position: relative;
      transition: all 0.3s ease;
      &:hover,
      &:active {
        cursor: pointer;
        opacity: 0.8;
      }
      .ic-toggle {
        display: block;
        position: absolute;
        width: 50px;
        height: 60px;
        cursor: pointer;
        top: 0;
        right: 0;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 15px;
          height: 1px;
          background-color: $color_main;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: 0.3s ease;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 15px;
          height: 1px;
          background-color: $color_main;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          transition: 0.3s ease;
          transform: rotate(90deg);
        }
      }
      &.-active {
        .ic-toggle:after {
          transform: rotate(0);
        }
      }
      &:before {
        content: "Q";
        display: block;
        font-family: $cinzelD;
        font-size: 2.4rem;
        color: $color_main;
        position: absolute;
        left: 20px;
        top: 7px;
      }
    }
    &__content {
      margin-top: 2px;
      padding: 24px 44px 24px 70px;
      font-size: 1.4rem;
      letter-spacing: 0.02em;
      display: block;
      background-color: #fff;
      position: relative;
      display: none;
      &:before {
        content: "A";
        display: block;
        font-family: $cinzelD;
        font-size: 2.4rem;
        color: $color_main;
        position: absolute;
        left: 24px;
        top: 12px;
      }
      .c-btn-row{
          display: flex;
          align-items: center;
          padding-top: 10px;
          @include mq(sp){
              flex-direction: column;
          }
      }
      .c-btn{
          height: 36px;
          width: 160px;
          a{
            font-size: 1.4rem;
          }
          &:not(:last-child){
              margin-right: 4px;
              @include mq(sp){
                margin-right: 0;
                margin-bottom: 1px;
              }
          }
      }
    }
  }
}

.-note{
    font-size: 1.3rem;
    letter-spacing: .08em;
}