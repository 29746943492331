.c-lp-note {
  background: #E0D9D3;
  padding: 50px 0;
  @include mq(sp) {
    padding: 30px 0;
  }
}
.c-lp-note__inner {
  padding: 0 20px;
}
.c-lp-note__content {
  padding: 35px;
  background: #fff;
}
.c-lp-note__text {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: .01em;
  margin-bottom: 24px;
  line-height: 1.6;
  @include mq(sp) {
    font-size: 1.6rem;
    margin-bottom: 12px;
  }
}
.c-lp-note__item {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.95;
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: 1.4rem;
  }
}