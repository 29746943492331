/* ========
list-fellowship
======== */
.o-list-fellowship {
  background-color: #f4f3f1;
  padding: 0 0 100px;
  .l-inner {
    max-width: 1000px;
    position: relative;
    z-index: 0;
    padding-top: 80px;
    @include mq(sp) {
      width: 100%;
      padding: 40px 15px 0;
    }
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .c-ttl{
      font-size: 2.2rem;
      margin-bottom: 30px;
      @include mq(sp){
        margin-bottom: 15px;
      }
  }
  .c-box-row{
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 20px;
      &:not(:last-child){
        margin-bottom: 60px;
        @include mq(sp){
            margin-bottom: 30px;
        }
      }
      .c-box-col{
          width: calc(50% - 80px);
          margin: 40px;
          @include mq(sp){
            width: 100%;
            margin: 0;
          }
          .c-arrow-list{
              li{
                  font-size: 1.4rem;
                  letter-spacing: .02em;
                  &:before{
                    content: '▶';
                    display: inline-block;
                    transform: scale(0.42857);
                    transform-origin: left center;
                    margin-right: -0.23333em;
                    color: #98806e;
                    color: $color_main;
                  }
              }
          }
      }
  }
}