/* ========
lead-instagram
======== */

.o-lead-instagram{
  padding: 80px 0;
  border-top: 1px solid #dfdad6;
  @include mq(sp) {
    padding: 60px 0;
  }
  .c-ttl-section{
    margin: 0 0 56px;
    @include mq(sp) {
      margin: 0 0 32px;
    }
  }
  &__content{
    position: relative;
    z-index: 0;
    margin: 0 0 0 50%;
    transform: translate(-50%,0);
    width: 100vw;
    min-width: $pc-min-width;
    max-width: 100vw;
    overflow: hidden;
    font-size: 0;
  }
  &__feed{
    display: flex;
    &__item{
      display: flex;
      flex: 0 0 auto;
      transform: translate(-100%,0);
      &:first-child{
        margin: 0 0 0 50%;
      }
      a{
        display: block;
        padding: 0 2px;
        flex: 0 0 auto;
        width: 200px;
        height: 200px;
        @include mq(pc) {
          transition: opacity .4s ease;
          &:hover{
            opacity: .6;
          }
        }
        @include mq(sp) {
          width: 150px;
          height: 150px;
        }
      }
      figure{
        width: 100%;
        height: 100%;
        flex: 0 0 auto;
        background: transparent center center no-repeat;
        background-size: cover;
        img{
          display: none;
        }
      }
    }
  }
}