.c-lp-point {
  padding-top: 120px;
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  overflow: hidden;
  counter-reset: point;
  @include mq(sp) {
    padding-top: 88px;
  }
  &__deco {
    width: 436px;
    height: 436px;
    position: absolute;
    top: 0;
    left: calc(50% - 770px);
    z-index: -1;
    @include mq(sp) {
      width: 218px;
      height: 218px;
      left: -20px;
    }
    svg {
      fill: #fff;
    }
  }
  &__content {
    padding: 0 20px 120px;
    @include mq(sp) {
      padding-bottom: 88px;
    }
  }
  &__item {
    width: 100%;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: flex-start;
    @include mq(pc) {
      &:nth-child(2n-1) {
        .c-lp-point__img {
          margin-top: 155px;
          margin-right: -104px;
        }
      }
      &:nth-child(2n) {
        flex-direction: row-reverse;
        .c-lp-point__img {
          margin-left: -104px;
        }
        .c-lp-point__textarea {
          margin-top: 80px;
        }
      }
    }
    @include mq(sp) {
      display: block;
    }
    &:nth-child(n+2) {
      margin: 100px 0 0;
    }
  }
  &__textarea {
    width: 552px;
    background-color: #fff;
    padding: 48px 52px;
    position: relative;
    z-index: 1;
    @include mq(sp) {
      width: calc(100% - 20px);
      padding: 20px;
      margin: -40px 0 0 auto;
    }
  }
  &__img {
    width: 552px;
    z-index: 0;
    flex: 0 0 auto;
    // aspect-ratio: 552 / 510;
    @include aspect-ratio(552, 510);
    @include mq(sp) {
      width: calc(100% - 20px);
    }
    img {
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  &__ttl {
    font-family: $cinzelD;
    font-size: 3.6rem;
    color: palettes(main);
    line-height: 1;
    letter-spacing: .05em;
    margin: 0 0 32px;
    @include mq(sp) {
      font-size: 2.4rem;
      margin: 0 0 20px;
    }
    // &:after {
    //   counter-increment: point 1;
    //   content: counter(point, decimal-leading-zero);
    //   font-size: 4rem;
    //   letter-spacing: .01em;
    //   @include mq(sp) {
    //     font-size: 2.8rem;
    //   }
    // }
  }
  &__copy {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .01em;
    margin: 0 0 24px;
    line-height: 1.6;
    @include mq(sp) {
      font-size: 1.6rem;
      display: flex;
      margin: 0 0 12px;
    }
    &:before {
      content: '－';
      color: palettes(main);
      padding: 0 .5em 0 0;
    }
  }
  &__desc {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.95;
    letter-spacing: .05em;
    padding: 0 0 0 27px;
    @include mq(sp) {
      font-size: 1.4rem;
      padding: 0 0 0 22px;
    }
  }
  .c-btn {
    margin: 64px auto 0;
  }
}