/* ========
gnav
======== */

.o-gnav{
  @include mq(sp) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 80px 0 120px;
    overflow-y: scroll;
    max-height: 100vh;
    background-color: #323232;
    // &:before{
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: #323232;
    //   z-index: -1;
    // }
  }
  >ul{
    display: flex;
    justify-content: center;
    @include mq(sp) {
      display: none;
    }
    >li{
      display: flex;
      font-family: $cormorant;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: .1em;
      text-shadow: 0 0 0 rgba(#fff,.5);
      color: #fff;
      transition: color .8s ease;
      @include mq(sp) {
        text-shadow: none;
        border-bottom: 1px solid #505050;
        &:first-child{
          border-top: 1px solid #505050;
        }
      }
      &:nth-child(n+2){
        &:before{
          content: '|';
          margin: 0 1em;
          @include mq(sp) {
            content: none;
          }
        }
      }
    }
  }
  &__lists{
    border-top: 1px solid #505050;
    @include mq(pc) {
      display: none;
    }
  }
  &__item{
    @include mq(sp) {
      width: 100%;
    }
    a{
      display: block;
      position: relative;
      z-index: 0;
      @include mq(pc) {
        &:hover{
          &:after{
            width: 100%;
            left: 0;
            right: auto;
          }
        }
      }
      @include mq(sp) {
        color: palettes(main);
        font-size: 2.2rem;
        $fontSizeSmall: 2.2;
        line-height: 1.4;
        width: 100%;
        padding: 8px 20px 12px;
        &:before{
          content: '▶';
          display: inline-block;
          transform: scale(#{0.6/$fontSizeSmall});
          transform-origin: left center;
          margin-right: -#{$fontSizeSmall/0.6/10}em;
          color: palettes(main);
        }
      }
      &:after{
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        left: auto;
        right: 0;
        bottom: 0;
        background-color: currentColor;
        transition: width .4s ease;
      }
      span{
        display: none;
        @include mq(sp) {
          display: block;
          color: #fff;
          font-size: 1.4rem;
          letter-spacing: .02em;
          font-weight: 500;
          margin: .15em 0 0 1.2em;
          line-height: 1;
        }
      }
    }
  }
  &__child{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(#fff,.9);
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: opacity .8s ease;
    @include mq(sp) {
      display: none;
    }
    &.-show{
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__btns{
    @include mq(pc) {
      display: none;
    }
    padding: 14px 0 0;
    .c-btn-large{
      margin: 20px auto;
    }
  }

  .c-link-list{
    @include mq(pc) {
      display: none;
    }
  }
}