//-----------------------
.c-logo{
  width: 200px;
  height: 36px;
  margin: 0 auto;
  position: relative;
  @include mq(sp) {
    width: 154px;
    height: 28px;
    z-index: 10;
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    .iconSvg{
      width: 100%;
      height: 100%;
      svg{
        .text{
          transition: fill .8s ease;
          fill: #fff;
        }
      }
    }
  }
}