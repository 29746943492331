/* ========
mainvisual
======== */

.o-mainvisual{
  width: 100%;
  height: calc(100vh - 54px);
  position: relative;
  z-index: 0;
  overflow: hidden;
  transform: translate(0,0);
  @include mq(sp) {
    height: calc(100vh - 70px);
  }
  &__slider{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
    // background-color: palettes(main,light);
    background-color: #333;
    li{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      transition: opacity 2s ease;
      transform: scale(1);
      opacity: 0;

      &.-scale{
        transform: scale(1.06);
        transition: transform 10s linear, opacity 2s ease;
      }
      &.-show{
        opacity: 1;
      }
    }
  }
  &__slide{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent center center no-repeat;
    background-size: cover;
  }
  &__timer{
    position: absolute;
    z-index: 1;
    width: 1px;
    height: 60px;
    background-color: rgba(#fff,.5);
    left: 40px;
    bottom: 20px;
    @include mq(sp) {
      height: 45px;
      left: 20px;
    }
    span{
      position: absolute;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      background-color: #fff;
      overflow: visible !important;
      &:after{
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 0;
        left: -10px;
        right: -10px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
  &__dots{
    position: absolute;
    left: 60px;
    bottom: 12px;
    display: flex;
    @include mq(sp) {
      left: 0;
      right: 0;
      justify-content: center;
    }
  }

  &__textarea{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
    text-align: center;
  }
  &__copy{
    font-family: $quentin;
    font-size: 4rem;
    color: #fff;
    font-weight: 400;
    display: block;
    line-height: 1.4;
    transform: rotate(-4deg);
    @include mq(sp) {
      font-size: 2.4rem;
      margin: 0 0 8px;
    }
  }
  &__ttl{
    font-family: $cinzelD;
    font-size: 4.7rem;
    color: #fff;
    letter-spacing: .1em;
    font-weight: 400;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      font-size: 3.4rem;
      line-height: 1.24;
    }
    &__deco{
      font-family: $quentin;
      font-size: 5.6rem;
      font-weight: 400;
      color: palettes(pink);
      position: absolute;
      display: block;
      transform: rotate(-8deg);
      right: -.5em;
      bottom: -.7em;
      @include mq(sp) {
        font-size: 3.1rem;
        right: 0;
      }
    }
  }
}