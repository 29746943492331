//-----------------------
.c-list-tab{
  display: flex;
  li{
    width: 156px;
    height: 50px;
    display: flex;
    position: relative;
    z-index: 0;
    border-right: 1px solid palettes(main,light);
    @include mq(sp) {
      height: 30px;
    }
    &:first-child{
      border-left: 1px solid palettes(main,light);
    }
    a{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: .02em;
      @include mq(sp) {
        font-size: 1.4rem;
      }
    }
    &.-current{
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -14px;
        height: 4px;
        background-color: palettes(main);
        @include mq(sp) {
          height: 2px;
          bottom: -10px;
        }
      }
      a{
        color: palettes(main);
      }
    }
  }
}