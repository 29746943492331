/* ========
lp-footer
======== */

.lp-footer {
  background-color: #fff;
  padding: 48px 0 56px;
  .c-logo {
    a {
      .iconSvg {
        svg {
          .text {
            fill: $color_text;
          }
        }
      }
    }
  }
  &__address {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    margin: 8px 0 0;
  }
  &__links {
    display: flex;
    justify-content: center;
    margin: 16px 0 0;
    a {
      font-size: 1.2rem;
      letter-spacing: .05em;
      display: inline-flex;
      font-weight: 500;
      align-items: center;
      .iconSvg {
        width: 1em;
        height: 1em;
        display: inline-block;
        margin: 0 0 0 .5em;
        svg {
          fill: palettes(main);
        }
      }
    }
  }
}