/* ========
list-case
======== */

.o-list-case{
  padding: 64px 0;
  .l-inner{
    width: 1000px;
    @include mq(sp) {
      width: 100%;
      padding: 0 15px;
    }
  }
  .c-ttl-mix{
    margin: 0 0 64px;
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    .c-item-case-simple{
      width: calc(20% + 1px);
      margin: 0 -1px 0 0;
      @include mq(pc) {
        &:nth-child(n+6){
          margin-top: 60px;
        }
      }
      @include mq(sp) {
        width: calc(50% + 1px);
        &:nth-child(n+3){
          margin-top: 24px;
        }
      }
    }
  }
}