//-----------------------
.c-btn-items{
  width: 160px;
  height: 50px;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: palettes(main);
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .02em;
    color: #fff;
    @include mq(sp) {
      font-size: 1.5rem;
    }
  }
  .iconSvg{
    display: block;
    width: 1.25em;
    height: 1.25em;
    flex: 0 0 auto;
    margin: -.125em .5em 0 0;
    svg{
      fill: #fff;
    }
  }

  // color
  &.-blue{
    a{
      background-color: palettes(blue);
    }
  }
  &.-pink{
    a{
      background-color: palettes(pink);
    }
  }
  &.-black{
    a{
      background-color: #323232;
    }
  }
}