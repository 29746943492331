/* ========
detail-propose
======== */

.o-detail-propose {
  background-color: palettes(main, lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 80px;
  }
  .l-inner {
    width: 1000px;
    background-color: #fff;
    position: relative;
    z-index: 0;
    padding: 60px;
    @include mq(sp) {
      width: calc(100% - 40px);
      padding: 20px;
    }
  }
  &__deco {
    position: absolute;
    z-index: -1;
    width: 436px;
    height: 436px;
    top: 100px;
    right: -96px;
    pointer-events: none;
    @include mq(sp) {
      width: 143px;
      height: 218px;
      top: 120px;
      right: -18px;
    }
    svg {
      fill: palettes(main, lightest);
    }
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
    &.-nobg {
      background: none;
      padding-top: 80px;
      padding-bottom: 110px;
      @include mq(sp) {
        padding-bottom: 40px;
      }
      &.-custom01{
        @include mq(sp) {
          padding-top: 0;
        }
      }
    }
  }
  .c-copy-basic {
    margin: 0 0 24px;
    text-align: center;
    @include mq(sp) {
      text-align: left;
    }
  }
  &__content {
    .c-box-basic {
      align-items: flex-start;
      margin-bottom: 80px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
      &__textarea {
        padding: 0 0 0 60px;
        @include mq(sp) {
          padding: 20px 0 0 0;
        }
        &:before {
          display: none;
        }
      }
      &__img {
        width: 620px;
        margin: -120px 0 0 -110px;
        &.-noshift {
          width: 400px;
          margin: 0;
          @include mq(sp) {
            width: 100%;
          }
        }
        @include mq(sp) {
          width: auto;
          margin: -20px -40px 0 -40px;
        }
        &::before {
          content: "";
          display: block;
          padding: 67.74% 0 0;
        }
        &.-noshift {
          &::before {
            padding: 110% 0 0 0;
          }
        }
        figure {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
          background: transparent center center no-repeat;
          background-size: cover;
          &::before {
            content: "";
            display: block;
            padding: 67.74% 0 0;
          }
        }
        img {
          display: none;
        }
      }
    }
    .c-box {
      margin-bottom: 80px;
      position: relative;
      &.-nmb {
        margin-bottom: 0;
      }
      @include mq(sp) {
        margin-bottom: 40px;
      }
      &__img {
        width: 440px;
        height: 240px;
        position: absolute;
        right: 0;
        top: 0;
        flex: 0 0 auto;
        overflow: hidden;
        &:before {
          content: "";
          display: block;
          padding: 54.54% 0 0;
        }
        @include mq(sp) {
          width: 100%;
          height: auto;
          position: relative;
        }
        a {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          overflow: hidden;
          figure {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent center center no-repeat;
            background-size: cover;
            transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
            &:before {
              content: "";
              display: block;
              padding: 54.54% 0 0;
            }
            img {
              display: none;
            }
          }
        }
      }
      &.-has-img {
        margin-bottom: 55px;
        .c-desc {
          width: calc(100% - 500px);
          @include mq(sp) {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
    }
    .point-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      @include mq(sp) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
      }
      &__col {
        width: calc(33.33% - 20px);
        margin: 0 10px;
        padding: 10px 30px 30px 30px;
        position: relative;
        @include mq(sp) {
          width: 100%;
          margin: 0 0 20px 0;
          &:last-child {
            margin: 0;
          }
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 40px 40px 0 0;
          border-color: #fff transparent transparent transparent;
        }
        .c-copy-basic {
          font-family: $cinzelD;
          font-weight: 400;
          letter-spacing: 0.15em;
          font-size: 1.8rem;
          color: palettes(main);
          line-height: 1;
          text-align: right;
          margin-right: -15px;
          .num {
            font-size: 3.4rem;
            padding-left: 0.25em;
            position: relative;
            top: 3px;
          }
        }
        &:nth-of-type(1) {
          background-color: #eae6e2;
        }
        &:nth-of-type(2) {
          background-color: #f2eeee;
        }
        &:nth-of-type(3) {
          background-color: #e4eef0;
        }
      }
    }
    .price-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -25px;
      margin-right: -25px;
      &__item {
        width: calc(50% - 50px);
        margin: 0 25px;
        display: flex;
        align-items: center;
        @include mq(sp) {
          width: calc(100% - 50px);
        }
        &__ttl {
          font-size: 1.5rem;
          letter-spacing: 0.05em;
        }
        &__deco {
          height: 1px;
          background-color: #98806e;
          flex: 1;
          margin: 0 16px;
        }
        &__price {
          font-size: 2rem;
          letter-spacing: 0.02em;
          color: #98806e;
          font-family: $cormorant;
        }
      }
    }
    .hr-dot {
      border-top: 0;
      background-position: center;
      background-size: cover;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .c-list-asta {
      font-size: 1.3rem;
    }
    .step-list {
      padding-top: 20px;
      margin-bottom: 40px;
      li {
        font-size: 1.4rem;
        line-height: 1.7;
        font-weight: 500;
        letter-spacing: 0.02em;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        .hd {
          font-size: 2.2rem;
          line-height: 1.36;
          letter-spacing: 0.1em;
          color: $color_main;
          font-family: $cormorant;
          display: block;
          width: 120px;
          border-bottom: 1px solid $color_main;
          margin-bottom: 10px;
        }
      }
    }
    .c-btn.-lines {
      height: 60px;
      width: 100%;
      a {
        font-size: 1.4rem;
        line-height: 1.4;
        text-align: center;
      }
    }
    .c-box-detail {
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: 80px;
        @include mq(sp) {
          margin-bottom: 40px;
        }
      }
      &__textarea {
        width: calc(100% - 440px);
        padding-left: 60px;
        @include mq(sp) {
          width: 100%;
          padding-left: 0;
        }
        .c-copy {
          font-size: 2.2rem;
          letter-spacing: 0.1em;
          margin-bottom: 20px;
          @include mq(sp) {
            font-size: 1.8rem;
            margin-bottom: 10px;
          }
        }
      }
      &.-rev {
        flex-direction: row-reverse;
        .c-box-detail__textarea {
          padding-left: 0;
          padding-right: 60px;
          @include mq(sp) {
            padding-right: 0;
          }
        }
      }
      &__img {
        width: 440px;
        @include mq(sp) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}

/* ========
list-propose-ring-step
======== */
.o-list-propose-ring-step {
  background-color: palettes(main, lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 80px;
  }
  .l-inner {
    width: 1000px;
    @include mq(sp) {
      width: 100%;
    }
  }
  .c-ttl-section{
    margin-bottom: 40px;
  }
  .c-box-deco-caption {
    display: block;
    &__row {
      display: flex;
      flex-direction: row-reverse;
      @include mq(sp){
        flex-direction: column;
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    .c-ttl-underline {
      font-size: 2.4rem;
      .hd {
        font-size: 3.6rem;
        letter-spacing: 0.01em;
        color: $color_main;
        margin-right: 20px;
        position: relative;
        top: 4px;
      }
    }
    &__img {
      width: 340px;
      @include mq(sp){
        width: 100%;
      }
    }
    &__textarea {
      margin-left: 0;
      @include mq(sp){
        margin-top: 0;
      }
      .c-desc {
        padding-right: 60px;
        @include mq(sp){
          padding-right: 0;
        }
      }
    }
  }
}
