//-----------------------
.c-btn{
  width: 240px;
  height: 54px;
  overflow: hidden;
  @include mq(sp) {
    width: 180px;
    height: 42px;
  }
  a,>span{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: palettes(main);
    font-family: $cormorant;
    font-size: 1.6rem;
    letter-spacing: .05em;
    font-weight: 400;
    color: #fff;
    position: relative;
    z-index: 0;
    transition: color .4s ease;
    @include mq(pc) {
      &:hover{
        color: palettes(main);
        .iconSvg{
          svg{
            fill: palettes(main);
          }
        }
        .itemBg{
          figure{
            transform: scale(1,1);
          }
        }
      }
    }
    @include mq(sp) {
      font-size: 1.5rem;
    }
  }
  .iconSvg{
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto 0;
    @include mq(sp) {
      width: 8px;
      height: 8px;
    }
    svg{
      fill: #fff;
      transition: fill .4s ease;
    }
  }
  .itemBg{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    pointer-events: none;
    figure{
      flex: 1 1 auto;
      background-color: lighten(palettes(main),40%);
      transform: scale(1,0);
      $max: 6;
      $easing: cubic-bezier(.05,.4,.6,.95);
      @for $i from 1 through $max {
        &:nth-child(#{$i}) {
          transition: .3s $easing #{($i - 1)*0.03}s;
          transform-origin: left bottom;
        }
      }
    }
  }

  // color
  &.-white{
    a{
      background-color: #fff;
      color: #323232;
      text-shadow: 0 0 0 currentColor;
      @include mq(pc) {
        &:hover{
          color: #fff;
        }
      }
    }
    .itemBg{
      figure{
        background-color: lighten(palettes(main),20%);
      }
    }
  }
  &.-ghost{
    a{
      background-color: #fff;
      color: palettes(main);
      text-shadow: 0 0 0 currentColor;
      border: 1px solid palettes(main);
    }
    .iconSvg{
      svg{
        fill: palettes(main);
      }
    }
  }

  // size
  &.-middle{
    width: 321px;
  }

  // layout
  &.-down{
    .iconSvg{
      transform: rotate(90deg);
    }
  }
  &.-rev{
    .iconSvg{
      left: 16px;
      right: auto;
      transform: scale(-1,1);
    }
  }
}