//-----------------------
.c-dot{
  cursor: pointer;
  padding: 8px;
  @include mq(sp) {
    padding: 6px;
  }
  &:after{
    content: '';
    width: 8px;
    height: 8px;
    background-color: #fff;
    display: block;
    transform: rotate(45deg);
    transition: .2s ease;
    @include mq(sp) {
      width: 6px;
      height: 6px;
    }
  }
  @include mq(pc) {
    &:hover{
      &:after{
        transform: rotate(45deg)scale(1.2);
      }
    }
  }
  &.-current{
    &:after{
      background-color: palettes(main);
    }
  }
}