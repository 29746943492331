/* ========
animation
======== */

.l-wrapper{
  opacity: 0;
}
.o-mainvisual{
  &__textarea{
    opacity: 0;
  }
  &__ttl{
    transform: scale(.88);
  }
}
body.-loaded{
  .l-wrapper{
    opacity: 1;
    transition: opacity 2s ease;
  }
  .o-mainvisual{
    &__textarea{
      opacity: 1;
      transition: opacity 2s ease;
    }
    &__ttl{
      transform: scale(1);
      transition: transform 8s cubic-bezier(.11,.74,.34,1);
    }
  }
}




@keyframes feedRoop{
  0%{
    transform: translate(0%,0);
  }
  100%{
    transform: translate(-100%,0);
  }
}