/* ========
lead-contact
======== */
.o-lead-contact {
  width: 100vw;
  min-width: 1200px;
  position: relative;
  z-index: 0;
  margin: 0 0 0 50%;
  transform: translate(-50%, 0);
  padding: 80px 0;
  @include mq(sp){
    padding: 20px;
    min-width: auto;
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    figure {
      background: transparent center center no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img {
        display: none;
      }
    }
  }
  .l-inner {
    position: relative;
    background-color: #fff;
    padding: 60px;
    text-align: center;
    z-index: 0;
    overflow: hidden;
    width: 1000px;
    @include mq(sp){
      width: 100%;
      padding: 20px;
    }
  }
  .c-ttl-section__en{
    text-align: center;
    @include mq(sp){
      margin-bottom: 10px;
    }
  }
  .c-copy{
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 30px;
    font-family: $cormorant;
    @include mq(sp){
      margin-bottom: 15px;
    }
    &.-phone{
      font-size: 2.2rem;
      margin-bottom: 20px;
      .-note{
        font-size: 1.3rem;
        padding-left: 10px;
        @include mq(sp){
          position: relative;
          top: -5px;
        }
      }
      @include mq(sp){
        .-num{
          display: flex;
          justify-content: center;
        }
      }
      .-num.-sub{
        font-size: 1.8rem;
        @include mq(sp){
          font-size: 2.2rem;
        }
      }
      .-main{
        color: #98806e;
        margin-right: .5em;
        @include mq(sp){
          width: 6em;
          text-align: left;
        }
        &.-sp-short{
          @include mq(sp){
            width: auto;
          }
        }
      }
      .-slash{
        padding: 0 .5em;
        @include mq(sp){
          display: none;
        }
      }
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 262px;
    height: 262px;
    top: -38px;
    right: -54px;
    pointer-events: none;
    @include mq(sp) {
      width: 131px;
      height: 131px;
      right: -27px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  .c-btn{
    &.-large{
      width: 300px;
    }
    a{
      .iconSvg[data-icon="mail"]{
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 42px;
        margin: auto 0;
        @include mq(sp){
          left: 12px;
        }
      } 
      &:hover{
        .iconSvg[data-icon="mail"]{
          fill: $color_main;
        }
        .st0{
          fill: $color_main;
        }
      }
    }
  }
}