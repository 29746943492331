//-----------------------
.c-item-report{
  width: 300px;
  @include mq(sp) {
    width: 240px;
  }
  a{
    display: block;
    @include mq(pc) {
      &:hover{
        figure{
          transform: scale(1.06);
        }
      }
    }
  }
  &__img{
    border: 4px solid #fff;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &:after{
      content: '';
      display: block;
      padding: 75% 0 0;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background: #fff center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
    img{
      display: none;
    }
  }
  &__name{
    font-family: $cormorant;
    font-size: 2.8rem;
    font-weight: 400;
    letter-spacing: .1em;
    color: palettes(main);
    text-align: center;
    @include mq(sp) {
      font-size: 1.9rem;
    }
  }
  &__date, &__info{
    font-size: 1.4rem;
    letter-spacing: .05em;
    text-align: center;
    line-height: 1.9;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
}