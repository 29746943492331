//-----------------------
.c-ttl-case{
  text-align: center;
  &__en{
    font-family: $cinzelD;
    color: palettes(main);
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: .1em;
    margin: 0 0 4px;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  &__label{
    font-family: $quentin;
    color: palettes(main);
    font-size: 4.4rem;
    line-height: 1.4;
    font-weight: 500;
    margin: 0 0 16px;
    @include mq(sp) {
      font-size: 2.8rem;
      margin: 0 0 8px;
    }
  }
  &__main{
    font-family: $cinzelD;
    color: palettes(main);
    font-size: 4rem;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: .15em;
    margin: 0 0 8px;
    @include mq(sp) {
      font-size: 2.4rem;
      margin: 0;
    }
  }
  &__ja{
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: .02em;
    @include mq(sp) {
      font-size: 1.4rem;
    }
  }
}