.c-lp-hero {
  width: 100%;
  height: 710px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 190px;
  text-shadow:
    0 0 40px rgba(0,0,0,20%),
    0 0 80px rgba(0,0,0,10%);
  @include mq(sp) {
    height: calc(100vh - 40px);
    min-height: 600px;
    padding: 0 0 80px;
  }
  &__img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    pointer-events: none;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(circle, rgba(#000,.12) 0%, rgba(#000,.03) 100%);
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left 50% top 33%;
    }
  }
  &__deco {
    color: #fff;
    font-family: $quentin;
    transform: rotate(-4deg);
    font-size: 3.2rem;
    text-align: center;
    @include mq(sp) {
      font-size: 2rem;
    }
  }
  &__ttl {
    color: #fff;
    text-align: center;
    margin: 12px 0 0;
    @include mq(sp) {
      margin: 8px 0 0;
    }
    span {
      display: block;
      font-family: $cinzelD;
      font-size: 6rem;
      font-weight: 400;
      letter-spacing: .05em;
      line-height: 1.3;
      @include mq(sp) {
        font-size: 3.5rem;
      }
    }
    small {
      display: block;
      font-size: 2.2rem;
      font-weight: 500;
      letter-spacing: .075em;
      line-height: 1;
      margin: 4px 0 0;
      @include mq(sp) {
        font-size: 1.4rem;
        margin: 12px 0 0;
      }
    }
  }
}